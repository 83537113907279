import React, { useState, useEffect } from 'react';
import { X, Loader2, Ticket } from 'lucide-react';
import clsx from 'clsx';
import * as API from '../../api';

type VouchersListModalProps = {
  onClose: () => void;
  store?: any;
};

const VouchersListModal: React.FC<VouchersListModalProps> = ({ onClose, store }) => {
  const [storeData, setStoreData] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (store) {
      loadVouchersList();
    }
  }, [store]);

  const loadVouchersList = async () => {
    setIsLoading(true);
    try {
      const response = await API.getVouchersByStore(store.IDLojista);
      setStoreData(response.data);
    } catch (error) {
      console.error('Error loading vouchers:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const formatDate = (dateString: string): string => {
    try {
      const date = new Date(dateString);
      return new Intl.DateTimeFormat('pt-BR', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
      }).format(date);
    } catch (error) {
      return dateString;
    }
  };

  return (
    <div className="bg-white rounded-2xl shadow-xl overflow-hidden max-w w-[1000px]">
      {/* Header */}
      <div className="px-8 py-6 bg-gradient-to-r from-blue-600 to-blue-700 flex items-center justify-between">
        <div className="flex items-center gap-4">
          <div className="p-3 bg-white/10 rounded-xl">
            <Ticket className="w-7 h-7 text-white" />
          </div>
          <h3 className="text-xl font-semibold text-white">
            {store?.RazaoSocial}
          </h3>
        </div>
        <button
          onClick={onClose}
          className="p-2.5 text-white/80 hover:text-white hover:bg-white/10 rounded-lg transition-colors"
        >
          <X className="w-6 h-6" />
        </button>
      </div>

      {/* Content */}
      <div className="max-h-[75vh] overflow-y-auto p-8">
        {isLoading ? (
          <div className="flex flex-col items-center justify-center py-16">
            <Loader2 className="w-10 h-10 text-blue-500 animate-spin" />
            <p className="mt-4 text-lg text-gray-500">Carregando cupons...</p>
          </div>
        ) : !storeData.length ? (
          <div className="flex flex-col items-center justify-center py-16">
            <div className="bg-gray-100 rounded-full p-4">
              <Ticket className="w-10 h-10 text-gray-400" />
            </div>
            <p className="mt-4 text-lg text-gray-500">Nenhum cupom encontrado</p>
          </div>
        ) : (
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-8 py-4 text-left text-sm font-medium text-gray-500 uppercase tracking-wider">
                    ID Cupom
                  </th>
                  <th className="px-8 py-4 text-left text-sm font-medium text-gray-500 uppercase tracking-wider">
                    Descrição
                  </th>
                  <th className="px-8 py-4 text-left text-sm font-medium text-gray-500 uppercase tracking-wider">
                    Data Início
                  </th>
                  <th className="px-8 py-4 text-left text-sm font-medium text-gray-500 uppercase tracking-wider">
                    Data Término
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {storeData.map((voucher) => (
                  <tr
                    key={voucher.IDVoucher}
                    className="hover:bg-gray-50 transition-colors"
                  >
                    <td className="px-8 py-5 whitespace-nowrap">
                      <span className="text-base font-medium text-gray-900">
                        {voucher.IDVoucher}
                      </span>
                    </td>
                    <td className="px-8 py-5 whitespace-nowrap">
                      <span className="text-base text-gray-900">
                        {voucher.DescricaoCupom}
                      </span>
                    </td>
                    <td className="px-8 py-5 whitespace-nowrap">
                      <span className={clsx(
                        'inline-flex rounded-full px-3 py-1 text-sm font-semibold',
                        new Date(voucher.DataInicioVigenciaCupom) <= new Date()
                          ? 'bg-green-100 text-green-800'
                          : 'bg-yellow-100 text-yellow-800'
                      )}>
                        {formatDate(voucher.DataInicioVigenciaCupom)}
                      </span>
                    </td>
                    <td className="px-8 py-5 whitespace-nowrap">
                      <span className={clsx(
                        'inline-flex rounded-full px-3 py-1 text-sm font-semibold',
                        new Date(voucher.DataFimVigenciaCupom) < new Date()
                          ? 'bg-red-100 text-red-800'
                          : 'bg-green-100 text-green-800'
                      )}>
                        {formatDate(voucher.DataFimVigenciaCupom)}
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
};

export default VouchersListModal;