import styled from "@emotion/styled";
import { Modal } from 'react-bootstrap';
import {KTSVG, toAbsoluteUrl} from '../../../../_metronic/helpers'
import { PageTitle } from "../../../../_metronic/layout/core";
import "react-datepicker/dist/react-datepicker.css";
import { useEffect, useState, useRef } from 'react';
import * as API from '../../../api';
import ConfirmModal from '../../modals/ConfirmModal';
import DownloadModal from '../../modals/DownloadModal';
import WorksheetIcon from "../../../components/WorksheetIcon";


const ScreenStyle = styled.div`
    position: relative;
    
    .new-rules-button {
        right: 0px;
    }
    .search-icon {
        position: absolute;
        padding-top: 8px;
        right: 45px;
    }

    .preloader {
        position: relative;
        display: inline-table;
        width: 32px;
        height: 32px;
        background: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200"><rect fill="%2300335D" stroke="%2300335D" stroke-width="15" width="30" height="30" x="25" y="50"><animate attributeName="y" calcMode="spline" dur="1" values="50;120;50;" keySplines=".5 0 .5 1;.5 0 .5 1" repeatCount="indefinite" begin="-.4"></animate></rect><rect fill="%2300335D" stroke="%2300335D" stroke-width="15" width="30" height="30" x="85" y="50"><animate attributeName="y" calcMode="spline" dur="1" values="50;120;50;" keySplines=".5 0 .5 1;.5 0 .5 1" repeatCount="indefinite" begin="-.2"></animate></rect><rect fill="%2300335D" stroke="%2300335D" stroke-width="15" width="30" height="30" x="145" y="50"><animate attributeName="y" calcMode="spline" dur="1" values="50;120;50;" keySplines=".5 0 .5 1;.5 0 .5 1" repeatCount="indefinite" begin="0"></animate></rect></svg>')
    }
    
    .action-btn {
        transition: opacity 0.1s ease;
    }

    .action-btn:hover {
        opacity: 0.6;
    }

    .inactive-item {
        background-color: rgba(255, 0, 0, 0.1);
    }
`;

type Props = {
    className?: string
  }

const formatDate = (dateString: string): string => {
    const options: Intl.DateTimeFormatOptions = {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false
    };

    return new Date(dateString).toLocaleString('pt-BR', options);
}

const CuponsReportMonth:React.FC<Props> = ({className}) => {
    const [message, setMessage] = useState<string>('');
    const [confirmModal, showConfirmModal] = useState(false);
    const [selectedList, setSelectedList] = useState<any>(null);
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState<any>([]);
    const [exportPromise, setExportPromise] = useState(null);
    const [url, setURL] = useState<any>(null);
    const [downloadModal, showDownloadModal] = useState(false);

    useEffect(() => {
        getData();
    }, []);

    useEffect(() => {
        showConfirmModal(message != '');
    }, [message]);

    useEffect(() => {
        showDownloadModal(exportPromise != null || url != null);
    }, [exportPromise, url]);

    const getData = async() => {
        setIsLoading(true);
        const response = await API.getMontlyReports('cupons');
        setData(response.data);
        setIsLoading(false);
    }

    const generateReport = async(item:any) => {
        if (item.url) {
            setURL(item.url);
        } else {
            const promise:any = API.getMontlyReports('cupons', item.id_report, true);
            setExportPromise(promise);
        }
    }

    return (
        <ScreenStyle>
            <PageTitle>Relatório de Cupons</PageTitle>
            {downloadModal && (
            <div className="fixed inset-0 bg-gray-900/50 backdrop-blur-sm flex items-center justify-center p-4 z-50">
                <DownloadModal
                    promise={exportPromise}
                    onClose={() => {
                        setExportPromise(null);
                        setURL(null);
                    }}
                />
            </div>)}
            <div className={`card mt-4`}>
                <div className='card-body py-3'>
                    <div className='table-responsive'>
                        { !isLoading && data.length ? 
                        <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                            <thead>
                                <tr className='fw-bold text-muted'>
                                    <th className='min-w-50px text-center'>Nome</th>
                                    <th className='min-w-200px text-center'>Período</th>
                                    <th className='min-w-100px text-center'>Download</th>
                                </tr>
                            </thead>
                            <tbody>
                            { data.map((item:any, index:any) => { return (
                                <tr key={`${item.id_report}_${index}`}>
                                    <td className="text-center">
                                        <span className='text-dark fw-semibold fs-6'>
                                            {item.name}
                                        </span>
                                    </td>
                                    <td className="text-center">
                                        <span className='text-dark fw-semibold d-block fs-6'>
                                            {item.month_year}
                                        </span>
                                    </td>
                                    <td className="text-center">
                                        <a href='#' className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 action-btn' onClick={() => generateReport(item)}>
                                            <WorksheetIcon/>
                                        </a>
                                    </td>
                                </tr>
                            )})}
                            </tbody>
                        </table> :
                        !data.length ? 
                        <div className='justify-content-center text-center mb-4'>
                            <p className='mb-5 mt-5'>Nenhum relatório gerado até o momento</p>
                        </div>
                        :
                        <div className='justify-content-center text-center mb-4'>
                            <p className='mb-5 mt-5'>Carregando, aguarde um momento</p>
                            <div className="preloader"></div>
                        </div> }
                    </div>
                </div>
            </div>
        </ScreenStyle>
    )
}

export default CuponsReportMonth;