import { useEffect, useState } from 'react';
import { FileSpreadsheet, Search, Loader2 } from 'lucide-react';
import * as API from '../../../api';
import { PageTitle } from '../../../../_metronic/layout/core';
import { Modal } from 'react-bootstrap';
import ConfirmModal from '../../modals/ConfirmModal';
import DownloadModal from '../../modals/DownloadModal';
import { DataTable } from './DataTable';
import { StatsCard } from './StatsCard';

const limit = 500;

const PositivacaoReportCurrent = () => {
  const [message, setMessage] = useState<string>('');
  const [confirmModal, showConfirmModal] = useState(false);
  const [selectedList, setSelectedList] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [allData, setAllData] = useState<any[]>([]);
  const [allDataUnformatted, setAllDataUnformatted] = useState<any[]>([]);
  const [displayData, setDisplayData] = useState<any[]>([]);
  const [exportPromise, setExportPromise] = useState(null);
  const [downloadModal, showDownloadModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    showConfirmModal(message !== '');
  }, [message]);

  useEffect(() => {
    showDownloadModal(exportPromise != null);
  }, [exportPromise]);

  // Filter data whenever search term changes
  useEffect(() => {
    const filteredData = filterDataBySearch(allData, searchTerm);
    setDisplayData(filteredData.slice(0, limit));
  }, [searchTerm, allData]);

  const getData = async () => {
    setIsLoading(true);
    try {
      const response = await API.getPositivacaoReport();
      const formattedData = formatDates(response.data);
      setAllData(formattedData);
      setAllDataUnformatted(response.data);
      setDisplayData(formattedData.slice(0, limit));
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const generateReport = async () => {
    const promise: any = API.getPositivacaoReport(null, true);
    setExportPromise(promise);
  };

  const generateFilteredReport = async () => {
    const promise: any = API.exportFilteredReport('positivacao', filterDataBySearch(allDataUnformatted, searchTerm));
    setExportPromise(promise);
  };

  const formatDates = (data: any[]) => {
    return data.map((row: any) => {
      const newRow = { ...row };
      for (let key in newRow) {
        if (isIsoDateString(newRow[key])) {
          newRow[key] = convertToBrazilianDate(newRow[key]);
        }
      }
      return newRow;
    });
  };

  const filterDataBySearch = (data: any[], term: string) => {
    if (!term) return data;
    
    const searchLower = term.toLowerCase();
    return data.filter((item: any) => 
      Object.values(item).some((value: any) => 
        value?.toString().toLowerCase().includes(searchLower)
      )
    );
  };

  return (
    <div className="min-h-screen">
      <div className="max-w mx-auto px-4 sm:px-6 lg:px-8">
        {/* Header */}
        <div className="mb-8">
          <PageTitle>Relatório - Positivação Listas</PageTitle>
          
          {allData?.length > 0 && (
            <div className="mt-6 grid gap-6 md:grid-cols-2 lg:grid-cols-3">
              <StatsCard
                title="Total de Registros"
                value={allData.length}
                description={allData.length > limit ? `Mostrando ${limit} de ${allData.length} registros` : 'Registros encontrados'}
                icon={<FileSpreadsheet className="w-6 h-6" />}
              />
              {searchTerm && (
                <StatsCard
                  title="Resultados da Busca"
                  value={filterDataBySearch(allData, searchTerm).length}
                  description="Registros encontrados com o termo pesquisado"
                  icon={<Search className="w-6 h-6" />}
                />
              )}
            </div>
          )}
        </div>

        {/* Search and Actions */}
        <div className="mb-6 flex flex-col sm:flex-row justify-between items-center gap-4">
          <div className="relative w-full sm:w-96">
            <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 w-5 h-5" />
            <input
              type="text"
              placeholder="Buscar em todos os registros..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="w-full pl-10 pr-4 py-2 rounded-lg border border-gray-200 focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            />
          </div>
          <div className='d-flex'>
            { searchTerm != '' && displayData.length ?
            <button
              onClick={generateFilteredReport}
              className="w-full sm:w-auto px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors duration-200 flex items-center justify-center gap-2 shadow-sm hover:shadow mr-2"
            > 
              <FileSpreadsheet className="w-5 h-5" />
              Exportar Planilha Filtrada
            </button> : null }
            <button
              onClick={generateReport}
              className="w-full sm:w-auto px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors duration-200 flex items-center justify-center gap-2 shadow-sm hover:shadow"
            >
              <FileSpreadsheet className="w-5 h-5" />
              Exportar Planilha
            </button>
          </div>
        </div>

        {/* Main Content */}
        <div className="bg-white rounded-xl shadow-sm border border-gray-200 overflow-hidden">
          {isLoading ? (
            <div className="flex flex-col items-center justify-center py-12">
              <Loader2 className="w-8 h-8 text-blue-500 animate-spin" />
              <p className="mt-4 text-gray-500">Carregando dados...</p>
            </div>
          ) : !allData.length ? (
            <div className="flex flex-col items-center justify-center py-12">
              <div className="bg-gray-100 rounded-full p-3">
                <FileSpreadsheet className="w-8 h-8 text-gray-400" />
              </div>
              <p className="mt-4 text-gray-500">Nenhum registro encontrado</p>
            </div>
          ) : (
            <DataTable data={displayData} />
          )}
        </div>
      </div>

      {/* Modals */}
      <Modal show={confirmModal} onHide={() => setMessage('')} centered>
        <ConfirmModal
          title={selectedList?.NomeLista}
          message={message}
          onClose={() => setMessage('')}
        />
      </Modal>

      
      {downloadModal && (
        <div className="fixed inset-0 bg-gray-900/50 backdrop-blur-sm flex items-center justify-center p-4 z-50">
          <DownloadModal
            promise={exportPromise}
            onClose={() => setExportPromise(null)}
          />
        </div>
      )}
    </div>
  );
};

// Helper functions
const isIsoDateString = (value: any) => {
  const isoDateRegex = /^\d{4}-\d{2}-\d{2}(?:[ T]\d{2}:\d{2}:\d{2}(?:\.\d{3}Z)?)?$/;
  return isoDateRegex.test(value);
};

const convertToBrazilianDate = (isoDate: string) => {
  if (typeof isoDate !== 'string') return null;
  const [datePart, timePartWithExtras] = isoDate.split('T').length > 1 
    ? isoDate.split('T') 
    : isoDate.split(' ');
  const [year, month, day] = datePart.split('-');
  return `${day}/${month}/${year}`;
};

export default PositivacaoReportCurrent;