import { Ticket, Check, X } from 'lucide-react';
import clsx from 'clsx';

type StoreTableProps = {
  data: any[];
  permissions: any[];
  onViewVouchers: (store: any) => void;
  onUpdateStatus: (store: any, newStatus: boolean) => void;
};

export const StoreTable = ({ data, permissions, onViewVouchers, onUpdateStatus }: StoreTableProps) => (
  <div className="overflow-x-auto">
    <table className="min-w-full divide-y divide-gray-200">
      <thead className="bg-gray-50">
        <tr>
          <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">CNPJ</th>
          <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Razão Social</th>
          <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Status</th>
          <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
            {permissions?.some(feature => feature.name.includes('cashback-lojas-participantes-edit-status')) ? 'Ações' : 'Detalhes'}
          </th>
        </tr>
      </thead>
      <tbody className="bg-white divide-y divide-gray-200">
        {data.map((store) => (
          <tr
            key={store.IDLojista}
            className={clsx(
              'hover:bg-gray-50 transition-colors',
              store.is_actived != '1' && 'bg-red-50'
            )}
          >
            <td className="px-6 py-4 whitespace-nowrap">
              <span className="text-sm font-medium text-gray-900">{store.CNPJ}</span>
            </td>
            <td className="px-6 py-4 whitespace-nowrap">
              <span className="text-sm text-gray-900">{store.RazaoSocial}</span>
            </td>
            <td className="px-6 py-4 whitespace-nowrap">
              <span className={clsx(
                'inline-flex rounded-full px-2 text-xs font-semibold leading-5',
                store.is_actived == '1'
                  ? 'bg-green-100 text-green-800'
                  : 'bg-red-100 text-red-800'
              )}>
                {store.is_actived == '1' ? 'Participante' : 'Não Participante'}
              </span>
            </td>
            <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
              <div className="flex justify-end items-center gap-2">
                {store.vouchers > 0 && (
                  <button
                    onClick={() => onViewVouchers(store)}
                    className="inline-flex items-center p-2 text-blue-600 hover:text-blue-900 hover:bg-blue-50 rounded-lg transition-colors"
                  >
                    <Ticket className="w-5 h-5" />
                    <span className="ml-1">{store.vouchers}</span>
                  </button>
                )}
                
                {permissions?.some(feature => feature.name.includes('cashback-lojas-participantes-edit-status')) && (
                  <>
                    <button
                      onClick={() => onUpdateStatus(store, true)}
                      disabled={store.is_actived == '1'}
                      className={clsx(
                        'p-2 rounded-lg transition-colors',
                        store.is_actived == '1'
                          ? 'text-gray-400 cursor-not-allowed'
                          : 'text-green-600 hover:bg-green-50'
                      )}
                    >
                      <Check className="w-5 h-5" />
                    </button>
                    <button
                      onClick={() => onUpdateStatus(store, false)}
                      disabled={store.is_actived != '1'}
                      className={clsx(
                        'p-2 rounded-lg transition-colors',
                        store.is_actived != '1'
                          ? 'text-gray-400 cursor-not-allowed'
                          : 'text-red-600 hover:bg-red-50'
                      )}
                    >
                      <X className="w-5 h-5" />
                    </button>
                  </>
                )}
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
);