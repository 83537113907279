import { DollarSign } from 'lucide-react';
import clsx from 'clsx';

type PriceTableProps = {
  data: any[];
  tamanhos: string[];
  quantities: number[];
  isEditable: boolean;
  editedValues: Map<number, string>;
  originalValues: Map<number, string>;
  onValueChange: (id: number, value: string) => void;
};

const formatCurrency = (value: number) => {
  return new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL'
  }).format(value);
};

export const PriceTable = ({
  data,
  tamanhos,
  quantities,
  isEditable,
  editedValues,
  originalValues,
  onValueChange
}: PriceTableProps) => (
  <div className="overflow-x-auto">
    <table className="min-w-full divide-y divide-gray-200">
      <thead className="bg-gray-50">
        <tr>
          <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider text-center">
            BUs
          </th>
          {tamanhos.map((tamanho, index) => (
            <th
              key={index}
              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider text-center"
            >
              {tamanho}
            </th>
          ))}
        </tr>
      </thead>
      <tbody className="bg-white divide-y divide-gray-200">
        {quantities.map((qty, rowIndex) => (
          <tr key={`row_${rowIndex}`} className="hover:bg-gray-50">
            <td className="px-6 py-4 whitespace-nowrap">
              <div className="flex items-center">
                <div className="p-2 bg-blue-50 rounded-lg mr-3">
                  <DollarSign className="w-5 h-5 text-blue-600" />
                </div>
                <span className="text-sm font-medium text-gray-900">
                  {qty}
                </span>
              </div>
            </td>
            {data[rowIndex]?.map((item: any, colIndex: number) => (
              <td key={colIndex} className="px-6 py-4 whitespace-nowrap">
                {isEditable ? (
                  <input
                    type="text"
                    value={editedValues.get(item.IDPreco) ?? item.value.toString()}
                    onChange={(e) => onValueChange(item.IDPreco, e.target.value)}
                    className={clsx(
                      'w-full px-3 py-2 rounded-lg border focus:ring-2 focus:ring-blue-500 focus:border-blue-500 text-center',
                      editedValues.has(item.IDPreco) &&
                        editedValues.get(item.IDPreco) !== originalValues.get(item.IDPreco)
                        ? 'bg-yellow-50 border-yellow-300'
                        : 'border-gray-200'
                    )}
                  />
                ) : (
                  <span className="text-sm text-gray-900 font-medium text-right block text-center">
                    {formatCurrency(item.value)}
                  </span>
                )}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  </div>
);