import React, { useEffect, useState } from 'react';
import { Ruler, Search, Loader2, Plus } from 'lucide-react';
import * as API from '../../../api';
import { PageTitle } from '../../../../_metronic/layout/core';
import { Modal } from 'react-bootstrap';
import ConfirmModal from '../../modals/ConfirmModal';
import { StatsCard } from './StatsCard';
import { SearchInput } from './SearchInput';
import { TamanhoTable } from './TamanhoTable';

type Props = {
  className?: string;
};

const TamanhoScreen: React.FC<Props> = () => {
  const [message, setMessage] = useState('');
  const [confirmModal, showConfirmModal] = useState(false);
  const [selectedTamanho, setSelectedTamanho] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [allData, setAllData] = useState<any[]>([]);
  const [displayData, setDisplayData] = useState<any[]>([]);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    loadTamanhos();
  }, []);

  useEffect(() => {
    showConfirmModal(message !== '');
  }, [message]);

  useEffect(() => {
    filterData();
  }, [searchTerm, allData]);

  const loadTamanhos = async () => {
    setIsLoading(true);
    try {
      const response = await API.getTamanhoList();
      setAllData(response.data);
      setDisplayData(response.data);
    } catch (error) {
      console.error('Error loading tamanhos:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const filterData = () => {
    if (!searchTerm) {
      setDisplayData(allData);
      return;
    }

    const filtered = allData.filter(tamanho =>
      tamanho.TamanhoLista.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setDisplayData(filtered);
  };

  return (
    <div className="min-h-screen bg-gray-50">
      <div className="max-w mx-auto px-4 sm:px-6 lg:px-8">
        {/* Header */}
        <div className="mb-8">
          <div className="flex items-center justify-between">
            <PageTitle>Lista de Tamanhos</PageTitle>
          </div>

          {!isLoading && (
            <div className="mt-6 grid gap-6 md:grid-cols-2 lg:grid-cols-3">
              <StatsCard
                title="Total de Tamanhos"
                value={allData.length}
                description="Tamanhos cadastrados"
                icon={<Ruler className="w-6 h-6" />}
              />
              {searchTerm && (
                <StatsCard
                  title="Resultados da Busca"
                  value={displayData.length}
                  description="Tamanhos encontrados"
                  icon={<Search className="w-6 h-6" />}
                />
              )}
            </div>
          )}
        </div>

        {/* Search */}
        <div className="mb-6">
          <SearchInput
            value={searchTerm}
            onChange={setSearchTerm}
            placeholder="Buscar por nome do tamanho..."
          />
        </div>

        {/* Main Content */}
        <div className="bg-white rounded-xl shadow-sm border border-gray-200 overflow-hidden">
          {isLoading ? (
            <div className="flex flex-col items-center justify-center py-12">
              <Loader2 className="w-8 h-8 text-blue-500 animate-spin" />
              <p className="mt-4 text-gray-500">Carregando dados...</p>
            </div>
          ) : !displayData.length ? (
            <div className="flex flex-col items-center justify-center py-12">
              <div className="bg-gray-100 rounded-full p-3">
                <Ruler className="w-8 h-8 text-gray-400" />
              </div>
              <p className="mt-4 text-gray-500">Nenhum tamanho encontrado</p>
            </div>
          ) : (
            <TamanhoTable data={displayData} />
          )}
        </div>
      </div>

      {/* Modals */}
      <Modal show={confirmModal} onHide={() => setMessage('')}>
        <ConfirmModal
          title={selectedTamanho?.TamanhoLista}
          message={message}
          onClose={(value) => {
            if (value) {
              // Handle confirmation action
            }
            setSelectedTamanho(null);
            setMessage('');
          }}
        />
      </Modal>
    </div>
  );
};

export default TamanhoScreen;