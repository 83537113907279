import React, { useState, useEffect } from 'react';
import { X, Loader2, Store, DollarSign, Building2, CheckCircle, XCircle } from 'lucide-react';
import clsx from 'clsx';
import * as API from '../../api';

type StoreListModalProps = {
  onClose: () => void;
  voucher?: any;
};

const formatCurrency = (value: number) => {
  return new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(value);
};

const formatCNPJ = (cnpj: string) => {
  return cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/, "$1.$2.$3/$4-$5");
};

const StoreListModal: React.FC<StoreListModalProps> = ({ onClose, voucher }) => {
  const [data, setData] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [stats, setStats] = useState({
    totalStores: 0,
    activeStores: 0,
    totalUsed: 0,
    totalValue: 0,
  });

  useEffect(() => {
    if (voucher) {
      loadStoreList();
    }
  }, [voucher]);

  const loadStoreList = async () => {
    setIsLoading(true);
    try {
      const response = await API.getStoresByVoucher(voucher.IDVoucher);
      setData(response.data);
      
      // Calculate stats
      const activeStores = response.data.filter((store: any) => store.is_actived).length;
      const usedCount = response.data.filter((store: any) => store.FlgCupomUtilizado == 'SIM').length;
      const totalValue = response.data.reduce((sum: number, store: any) => 
        sum + parseFloat(store.ValorUtilizadoCupom || 0), 0
      );

      setStats({
        totalStores: response.data.length,
        activeStores,
        totalUsed: usedCount,
        totalValue,
      });
    } catch (error) {
      console.error('Error loading stores:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="fixed inset-0 z-50 overflow-y-auto">
      <div className="flex min-h-screen items-center justify-center p-4">
        {/* Backdrop */}
        <div 
          className="fixed inset-0 bg-black/50 backdrop-blur-sm"
          onClick={onClose}
        />

        {/* Modal Content */}
        <div className="relative bg-white rounded-2xl shadow-xl w-[95vw] max-w w-[1100px] overflow-hidden">
          {/* Header */}
          <div className="px-8 py-6 bg-gradient-to-r from-blue-600 to-blue-700 flex items-center justify-between">
            <div className="flex items-center gap-4">
              <div className="p-3 bg-white/10 rounded-xl">
                <Store className="w-7 h-7 text-white" />
              </div>
              <div className="text-white">
                <h3 className="text-xl font-semibold text-white">
                  Cupom #{voucher?.IDVoucher}
                </h3>
                <p className="text-white/80 text-sm mt-1">
                  {voucher?.DescricaoCupom}
                </p>
              </div>
            </div>
            <button
              onClick={onClose}
              className="p-2.5 text-white/80 hover:text-white hover:bg-white/10 rounded-lg transition-colors"
            >
              <X className="w-6 h-6" />
            </button>
          </div>

          {/* Stats */}
          {!isLoading && (
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 p-6">
              <div className="bg-white rounded-xl border border-gray-200 p-4 shadow-sm">
                <div className="flex items-center gap-3">
                  <div className="p-2 bg-blue-50 rounded-lg">
                    <Building2 className="w-5 h-5 text-blue-600" />
                  </div>
                  <div>
                    <p className="text-sm text-gray-500">Total de Lojas</p>
                    <p className="text-lg font-semibold text-gray-900">{stats.totalStores}</p>
                  </div>
                </div>
              </div>

              <div className="bg-white rounded-xl border border-gray-200 p-4 shadow-sm">
                <div className="flex items-center gap-3">
                  <div className="p-2 bg-green-50 rounded-lg">
                    <CheckCircle className="w-5 h-5 text-green-600" />
                  </div>
                  <div>
                    <p className="text-sm text-gray-500">Lojas Ativas</p>
                    <p className="text-lg font-semibold text-gray-900">{stats.activeStores}</p>
                  </div>
                </div>
              </div>

              <div className="bg-white rounded-xl border border-gray-200 p-4 shadow-sm">
                <div className="flex items-center gap-3">
                  <div className="p-2 bg-yellow-50 rounded-lg">
                    <Store className="w-5 h-5 text-yellow-600" />
                  </div>
                  <div>
                    <p className="text-sm text-gray-500">Cupons Utilizados</p>
                    <p className="text-lg font-semibold text-gray-900">{stats.totalUsed}</p>
                  </div>
                </div>
              </div>

              <div className="bg-white rounded-xl border border-gray-200 p-4 shadow-sm">
                <div className="flex items-center gap-3">
                  <div className="p-2 bg-purple-50 rounded-lg">
                    <DollarSign className="w-5 h-5 text-purple-600" />
                  </div>
                  <div>
                    <p className="text-sm text-gray-500">Valor Total</p>
                    <p className="text-lg font-semibold text-gray-900">
                      {formatCurrency(stats.totalValue)}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* Content */}
          <div className="max-h-[calc(100vh-20rem)] overflow-y-auto px-6 pb-6">
            {isLoading ? (
              <div className="flex flex-col items-center justify-center py-12">
                <Loader2 className="w-10 h-10 text-blue-500 animate-spin" />
                <p className="mt-4 text-lg text-gray-500">Carregando lojas...</p>
              </div>
            ) : !data.length ? (
              <div className="flex flex-col items-center justify-center py-12">
                <div className="bg-gray-100 rounded-full p-4">
                  <Store className="w-10 h-10 text-gray-400" />
                </div>
                <p className="mt-4 text-lg text-gray-500">Nenhuma loja encontrada</p>
              </div>
            ) : (
              <div className="overflow-x-auto">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        CNPJ
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Razão Social
                      </th>
                      <th className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Cupom Utilizado
                      </th>
                      <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Valor Utilizado
                      </th>
                      <th className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Status
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {data.map((store) => (
                      <tr
                        key={store.IDLojista}
                        className={clsx(
                          'hover:bg-gray-50 transition-colors',
                          !store.is_actived && 'bg-red-50'
                        )}
                      >
                        <td className="px-6 py-4 whitespace-nowrap">
                          <span className="text-sm font-medium text-gray-900">
                            {formatCNPJ(store.CNPJ)}
                          </span>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <span className="text-sm text-gray-900">
                            {store.RazaoSocial}
                          </span>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="flex justify-center">
                            {store.FlgCupomUtilizado == 'SIM' ? (
                              <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                                Sim
                              </span>
                            ) : (
                              <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800">
                                Não
                              </span>
                            )}
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-right">
                          <span className="text-sm font-medium text-gray-900">
                            {formatCurrency(store.ValorUtilizadoCupom || 0)}
                          </span>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="flex justify-center">
                            {store.is_actived ? (
                              <span className="inline-flex items-center gap-1.5 px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                                <span className="w-1.5 h-1.5 rounded-full bg-green-400" />
                                Participante
                              </span>
                            ) : (
                              <span className="inline-flex items-center gap-1.5 px-2.5 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800">
                                <span className="w-1.5 h-1.5 rounded-full bg-red-400" />
                                Não Participante
                              </span>
                            )}
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default StoreListModal;