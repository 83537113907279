import React, { useState, useEffect } from 'react';
import { X, Upload, AlertTriangle, CheckCircle, Loader2, FileText } from 'lucide-react';
import * as API from '../../api';
import clsx from 'clsx';

type Props = {
  onClose: (updated?: boolean) => void;
  promise: any;
  error?: boolean;
};

const errors: Record<string, string> = {
  'exists': 'Já Cadastrado',
  'name': 'Nome Incorreto',
  'tamanho': 'Tamanho não existe',
  'regional': 'Regional não existe',
  'bu': 'BU não existe',
  'notfound': 'Lista não reconhecida',
};

const UploadListModal: React.FC<Props> = ({ onClose, promise, error: propError }) => {
  const [loading, setLoading] = useState(true);
  const [addLoading, setAddLoading] = useState(false);
  const [error, setError] = useState(false);
  const [resultsData, setResultsData] = useState<any[]>([]);
  const [hasErrors, setHasErrors] = useState(false);
  const [incorrectCount, setIncorrectCount] = useState(0);

  useEffect(() => {
    setError(false);
    setLoading(true);
    
    if (promise) {
      promise
        .then((result: any) => {
          setResultsData(result.data);
          setIncorrectCount(result.data.filter((item: any) => 
            Array.isArray(item.errors) && item.errors.length !== 0
          ).length);
        })
        .catch(() => setError(true))
        .finally(() => setLoading(false));
    }
  }, [promise]);

  useEffect(() => {
    if (!resultsData?.length) {
      setHasErrors(false);
      return;
    }
    setHasErrors(resultsData.some(item => item.errors?.length > 0));
  }, [resultsData]);

  const addItems = async () => {
    setAddLoading(true);
    try {
      await API.addLists(resultsData);
      onClose(true);
    } catch (error) {
      console.error('Error adding lists:', error);
      setAddLoading(false);
    }
  };

  const renderLoadingState = () => (
    <div className="flex flex-col items-center justify-center py-16">
      <Loader2 className="w-12 h-12 text-blue-500 animate-spin" />
      <p className="mt-6 text-lg text-gray-600">
        {addLoading ? 'Cadastrando listas...' : 'Validando dados...'}
      </p>
    </div>
  );

  const renderErrorState = () => (
    <div className="flex flex-col items-center justify-center py-16">
      <div className="p-3 bg-red-100 rounded-full">
        <AlertTriangle className="w-12 h-12 text-red-500" />
      </div>
      <p className="mt-6 text-lg text-red-600">Houve um problema com seu arquivo</p>
    </div>
  );

  return (
    <div className="fixed inset-0 z-[9999] overflow-y-auto">
      <div className="fixed inset-0 bg-black/50 backdrop-blur-sm z-[9999]" />
      <div className="relative z-[10000] flex min-h-screen items-center justify-center p-4">
        {/* Modal Content */}
        <div className="relative bg-white rounded-2xl shadow-xl w-[95vw] max-w w-[1100px] overflow-hidden">
          {/* Header */}
          <div className="px-8 py-6 bg-gradient-to-r from-blue-600 to-blue-700 flex items-center justify-between">
            <div className="flex items-center gap-4">
              <div className="p-3 bg-white/10 rounded-xl">
                <FileText className="w-7 h-7 text-white" />
              </div>
              <h3 className="text-xl font-semibold text-white">
                Validação e Cadastro de Listas
              </h3>
            </div>
            {!loading && !addLoading && !propError && !error && (
              <button
                onClick={() => onClose()}
                className="p-2.5 text-white/80 hover:text-white hover:bg-white/10 rounded-lg transition-colors"
              >
                <X className="w-6 h-6" />
              </button>
            )}
          </div>

          {/* Content */}
          <div className="max-h-[75vh] overflow-y-auto">
            {(loading || addLoading) ? (
              renderLoadingState()
            ) : propError || error ? (
              renderErrorState()
            ) : (
              <div className="p-8">
                <div className="bg-white rounded-xl border border-gray-200 overflow-hidden">
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                      <tr>
                        <th className="px-6 py-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">ID Lista</th>
                        <th className="px-6 py-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Nome</th>
                        <th className="px-6 py-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Tamanho</th>
                        <th className="px-6 py-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Regional</th>
                        <th className="px-6 py-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">BU</th>
                        <th className="px-6 py-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Problemas</th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200">
                      {resultsData.filter(item => item.errors?.length).map((item) => (
                        <tr key={item.IDLista} className="bg-red-50">
                          <td className={clsx(
                            "px-6 py-4 whitespace-nowrap text-sm",
                            (item.errors.includes('exists') || item.errors.includes('notfound')) && 'text-red-600 font-medium'
                          )}>
                            {item.IDLista}
                          </td>
                          <td className={clsx(
                            "px-6 py-4 whitespace-nowrap text-sm",
                            item.errors.includes('name') && 'text-red-600 font-medium'
                          )}>
                            {item.NomeLista}
                          </td>
                          <td className={clsx(
                            "px-6 py-4 whitespace-nowrap text-sm",
                            item.errors.includes('tamanho') && 'text-red-600 font-medium'
                          )}>
                            {item.Tamanho}
                          </td>
                          <td className={clsx(
                            "px-6 py-4 whitespace-nowrap text-sm",
                            item.errors.includes('regional') && 'text-red-600 font-medium'
                          )}>
                            {item.Regional}
                          </td>
                          <td className={clsx(
                            "px-6 py-4 whitespace-nowrap text-sm",
                            item.errors.includes('bu') && 'text-red-600 font-medium'
                          )}>
                            {item.BU}
                          </td>
                          <td className="px-6 py-4">
                            <div className="space-y-1">
                              {item.errors.map((error: string) => (
                                <div key={error} className="text-sm text-red-600 flex items-center gap-1">
                                  <div className="w-1 h-1 rounded-full bg-red-600" />
                                  {errors[error]}
                                </div>
                              ))}
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>

                {/* Summary */}
                <div className="mt-8 space-y-4">
                  {hasErrors ? (
                    resultsData.length === incorrectCount ? (
                      <div className="flex items-center gap-3 text-red-600">
                        <AlertTriangle className="w-6 h-6" />
                        <p className="text-lg font-medium">Nenhum registro será cadastrado</p>
                      </div>
                    ) : (
                      <div className="space-y-4">
                        <div className="flex items-center gap-3 text-red-600">
                          <AlertTriangle className="w-6 h-6" />
                          <p className="text-lg font-medium">
                            {incorrectCount} registro(s) não serão cadastrados
                          </p>
                        </div>
                        <div className="flex items-center gap-3 text-green-600">
                          <CheckCircle className="w-6 h-6" />
                          <p className="text-lg font-medium">
                            {resultsData.length - incorrectCount} registro(s) serão cadastrados
                          </p>
                        </div>
                      </div>
                    )
                  ) : (
                    <div className="flex items-center gap-3 text-green-600">
                      <CheckCircle className="w-6 h-6" />
                      <p className="text-lg font-medium">
                        Todos os {resultsData.length} registros estão corretos
                      </p>
                    </div>
                  )}
                </div>

                {/* Actions */}
                {resultsData.length !== incorrectCount && (
                  <div className="mt-8 flex justify-center gap-4">
                    <button
                      onClick={() => onClose()}
                      className="px-6 py-3 bg-gray-100 text-gray-700 rounded-lg hover:bg-gray-200 transition-colors"
                    >
                      Cancelar
                    </button>
                    <button
                      onClick={addItems}
                      disabled={addLoading}
                      className="px-6 py-3 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors disabled:opacity-50 disabled:cursor-not-allowed flex items-center gap-2"
                    >
                      {addLoading && <Loader2 className="w-5 h-5 animate-spin" />}
                      {addLoading ? 'Cadastrando...' : 'Cadastrar Listas'}
                    </button>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UploadListModal;