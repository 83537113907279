import Select from 'react-select';

type FilterBarProps = {
  tamanhos: any[];
  selectedTamanho: any;
  onTamanhoChange: (value: any) => void;
  regionais: any[];
  selectedRegional: any;
  onRegionalChange: (value: any) => void;
  bus: any[];
  selectedBU: any;
  onBUChange: (value: any) => void;
};

export const FilterBar = ({
  tamanhos,
  selectedTamanho,
  onTamanhoChange,
  regionais,
  selectedRegional,
  onRegionalChange,
  bus,
  selectedBU,
  onBUChange
}: FilterBarProps) => (
  <div className="flex flex-col sm:flex-row gap-4">
    <Select
      className="flex-1"
      value={selectedTamanho}
      placeholder="Tamanho"
      options={[
        { value: 'all', label: 'Todos os Tamanhos' },
        ...tamanhos.map((item: any) => ({
          value: item.IDTamanho,
          label: item.TamanhoLista
        }))
      ]}
      onChange={onTamanhoChange}
    />

    <Select
      className="flex-1"
      value={selectedRegional}
      placeholder="Regional"
      options={[
        { value: 'all', label: 'Todas as Regionais' },
        ...regionais.map((item: any) => ({
          value: item.IDRegional,
          label: item.NomeRegional
        }))
      ]}
      onChange={onRegionalChange}
    />

    <Select
      className="flex-1"
      value={selectedBU}
      placeholder="BU"
      options={[
        { value: 'all', label: 'Todas as BUs' },
        ...bus.map((item: any) => ({
          value: item.IDBu,
          label: item.GrupoFamilia
        }))
      ]}
      onChange={onBUChange}
    />
  </div>
);