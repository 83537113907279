type FilterSelectProps = {
    value: string;
    onChange: (value: string) => void;
  };
  
  export const FilterSelect = ({ value, onChange }: FilterSelectProps) => (
    <select
      value={value}
      onChange={(e) => onChange(e.target.value)}
      className="w-full sm:w-64 px-4 py-2 rounded-lg border border-gray-200 focus:ring-2 focus:ring-blue-500 focus:border-blue-500 bg-white"
    >
      <option value="associated">Cupons Associados</option>
      <option value="unassociated">Cupons sem Associação</option>
      <option value="all">Todos os Cupons</option>
    </select>
  );