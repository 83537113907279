import { Ruler } from 'lucide-react';

type TamanhoTableProps = {
  data: any[];
};

export const TamanhoTable = ({ data }: TamanhoTableProps) => (
	<div className="overflow-x-auto">
		<table className="min-w-full divide-y divide-gray-200">
			<thead className="bg-gray-50">
				<tr>
					<th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider text-center">
						Nome do Tamanho
					</th>
				</tr>
			</thead>
			<tbody className="bg-white divide-y divide-gray-200">
				{data.map((tamanho) => (
				<tr key={tamanho.IDTamanho} className="hover:bg-gray-50 transition-colors text-center justify-center">
					<td className="px-6 py-4 whitespace-nowrap text-center">
						<div className="flex items-center justify-center">
							<div className="p-2 bg-blue-50 rounded-lg mr-3 flex-shrink-0">
								<Ruler className="w-5 h-5 text-blue-600" />
							</div>
							<span className="text-sm font-medium text-gray-900">
							{tamanho.TamanhoLista}
							</span>
						</div>
					</td>
				</tr>
				))}
		</tbody>
		</table>
	</div>
);