import { Edit2 } from 'lucide-react';

type RegionalTableProps = {
  data: any[];
  onEdit: (regional: any) => void;
};

export const RegionalTable = ({ data, onEdit }: RegionalTableProps) => (
  <div className="overflow-x-auto">
    <table className="min-w-full divide-y divide-gray-200">
      <thead className="bg-gray-50">
        <tr>
          <th className="px-6 py-3 text-left text-xs text-center font-medium text-gray-500 uppercase tracking-wider">
            Nome
          </th>
        </tr>
      </thead>
      <tbody className="bg-white divide-y divide-gray-200">
        {data.map((regional) => (
          <tr key={regional.IDRegional} className="hover:bg-gray-50 transition-colors">
            <td className="px-6 py-4 text-center whitespace-nowrap">
              <span className="text-sm text-gray-900">
                {regional.NomeRegional}
              </span>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
);