import { Check, X } from 'lucide-react';
import clsx from 'clsx';

type ListTableProps = {
  data: any[];
  permissions: any[];
  onUpdateStatus: (list: any, newStatus: boolean) => void;
};

export const ListTable = ({ data, permissions, onUpdateStatus }: ListTableProps) => (
  <div className="overflow-x-auto">
    <table className="min-w-full divide-y divide-gray-200">
      <thead className="bg-gray-50">
        <tr>
          <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            ID Lista
          </th>
          <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            Nome
          </th>
          <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            Tamanho
          </th>
          <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            Regional
          </th>
          <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            BU
          </th>
          {permissions?.some(feature => feature.name.includes('cashback-listas-edit-status')) && (
            <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
              Ações
            </th>
          )}
        </tr>
      </thead>
      <tbody className="bg-white divide-y divide-gray-200">
        {data.map((list) => (
          <tr
            key={list.IDLista}
            className={clsx(
              'hover:bg-gray-50 transition-colors',
              !list.is_actived && 'bg-red-50'
            )}
          >
            <td className="px-6 py-4 whitespace-nowrap">
              <span className="text-sm font-medium text-gray-900">
                {list.IDLista}
              </span>
            </td>
            <td className="px-6 py-4 whitespace-nowrap">
              <span className="text-sm text-gray-900">
                {list.NomeLista}
              </span>
            </td>
            <td className="px-6 py-4 whitespace-nowrap">
              <span className="text-sm text-gray-900">
                {list.Tamanho}
              </span>
            </td>
            <td className="px-6 py-4 whitespace-nowrap">
              <span className="text-sm text-gray-900">
                {list.Regional}
              </span>
            </td>
            <td className="px-6 py-4 whitespace-nowrap">
              <span className="text-sm text-gray-900">
                {list.BU}
              </span>
            </td>
            {permissions?.some(feature => feature.name.includes('cashback-listas-edit-status')) && (
              <td className="px-6 py-4 whitespace-nowrap text-right">
                <div className="flex justify-end items-center gap-2">
                  <button
                    onClick={() => onUpdateStatus(list, true)}
                    disabled={list.is_actived}
                    className={clsx(
                      'p-2 rounded-lg transition-colors',
                      list.is_actived
                        ? 'text-gray-400 cursor-not-allowed'
                        : 'text-green-600 hover:bg-green-50'
                    )}
                  >
                    <Check className="w-5 h-5" />
                  </button>
                  <button
                    onClick={() => onUpdateStatus(list, false)}
                    disabled={!list.is_actived}
                    className={clsx(
                      'p-2 rounded-lg transition-colors',
                      !list.is_actived
                        ? 'text-gray-400 cursor-not-allowed'
                        : 'text-red-600 hover:bg-red-50'
                    )}
                  >
                    <X className="w-5 h-5" />
                  </button>
                </div>
              </td>
            )}
          </tr>
        ))}
      </tbody>
    </table>
  </div>
);