import React from 'react';
import { FolderPlus, Plus } from 'lucide-react';

interface EmptyStateProps {
  onCreateCampaign: () => void;
}

const EmptyState: React.FC<EmptyStateProps> = ({ onCreateCampaign }) => {
  return (
    <div className="text-center py-12">
      <div className="flex justify-center">
        <div className="relative">
          <div className="absolute -inset-1 bg-gradient-to-r from-blue-500 to-purple-500 rounded-full blur opacity-25"></div>
          <div className="relative bg-white p-4 rounded-full">
            <FolderPlus className="h-12 w-12 text-blue-600" />
          </div>
        </div>
      </div>
      <h3 className="mt-6 text-xl font-semibold text-gray-900">
        Nenhuma campanha encontrada
      </h3>
      <p className="mt-2 text-sm text-gray-600 max-w-sm mx-auto">
        Comece criando sua primeira campanha para visualizar projeções e resultados de forma simples e rápida.
      </p>
      <div className="mt-6">
        <button
          onClick={onCreateCampaign}
          className="inline-flex items-center px-4 py-2 border border-transparent rounded-lg shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        >
          <Plus className="h-4 w-4 mr-2" />
          Criar Primeira Campanha
        </button>
      </div>
    </div>
  );
};

export default EmptyState;