import React, { useEffect, useState } from 'react';
import { Building2, Search, Loader2, Plus } from 'lucide-react';
import * as API from '../../../api';
import { PageTitle } from '../../../../_metronic/layout/core';
import { Modal } from 'react-bootstrap';
import ConfirmModal from '../../modals/ConfirmModal';
import { StatsCard } from './StatsCard';
import { SearchInput } from './SearchInput';
import { BUTable } from './BUTable';

type Props = {
  className?: string;
};

const BUScreen: React.FC<Props> = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [allData, setAllData] = useState<any[]>([]);
  const [displayData, setDisplayData] = useState<any[]>([]);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    loadBUs();
  }, []);

  useEffect(() => {
    filterData();
  }, [searchTerm, allData]);

  const loadBUs = async () => {
    setIsLoading(true);
    try {
      const response = await API.getBUList();
      setAllData(response.data);
      setDisplayData(response.data);
    } catch (error) {
      console.error('Error loading BUs:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const filterData = () => {
    if (!searchTerm) {
      setDisplayData(allData);
      return;
    }

    const filtered = allData.filter(bu =>
      bu.GrupoFamilia.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setDisplayData(filtered);
  };

  return (
    <div className="min-h-screen bg-gray-50">
      <div className="max-w mx-auto px-4 sm:px-6 lg:px-8">
        {/* Header */}
        <div className="mb-8">
          <div className="flex items-center justify-between">
            <PageTitle>Lista de BU</PageTitle>
          </div>

          {!isLoading && (
            <div className="mt-6 grid gap-6 md:grid-cols-2 lg:grid-cols-3">
              <StatsCard
                title="Total de BUs"
                value={allData.length}
                description="BUs cadastradas"
                icon={<Building2 className="w-6 h-6" />}
              />
              {searchTerm && (
                <StatsCard
                  title="Resultados da Busca"
                  value={displayData.length}
                  description="BUs encontradas"
                  icon={<Search className="w-6 h-6" />}
                />
              )}
            </div>
          )}
        </div>

        {/* Search */}
        <div className="mb-6">
          <SearchInput
            value={searchTerm}
            onChange={setSearchTerm}
            placeholder="Buscar por nome da BU..."
          />
        </div>

        {/* Main Content */}
        <div className="bg-white rounded-xl shadow-sm border border-gray-200 overflow-hidden">
          {isLoading ? (
            <div className="flex flex-col items-center justify-center py-12">
              <Loader2 className="w-8 h-8 text-blue-500 animate-spin" />
              <p className="mt-4 text-gray-500">Carregando dados...</p>
            </div>
          ) : !displayData.length ? (
            <div className="flex flex-col items-center justify-center py-12">
              <div className="bg-gray-100 rounded-full p-3">
                <Building2 className="w-8 h-8 text-gray-400" />
              </div>
              <p className="mt-4 text-gray-500">Nenhuma BU encontrada</p>
            </div>
          ) : (
            <BUTable data={displayData} />
          )}
        </div>
      </div>
    </div>
  );
};

export default BUScreen;