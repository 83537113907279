import React from 'react';
import { X } from 'lucide-react';
import CampaignForm from './CampaignForm';

interface CampaignFormModalProps {
  onClose: () => void;
}

const CampaignFormModal: React.FC<CampaignFormModalProps> = ({ onClose }) => {
  return (
    <div className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center z-50">
      <div className="bg-white rounded-xl shadow-2xl w-full max-w-4xl max-h-[90vh] overflow-y-auto relative">
        <div className="sticky top-0 bg-white px-6 py-4 border-b border-gray-200 flex justify-between items-center">
          <div>
            <h2 className="text-2xl font-bold text-gray-900">Nova Campanha</h2>
            <p className="text-sm text-gray-500 mt-1">Preencha os dados para criar uma nova campanha</p>
          </div>
          <button
            onClick={onClose}
            className="p-2 hover:bg-gray-100 rounded-full transition-colors"
          >
            <X className="h-6 w-6 text-gray-500" />
          </button>
        </div>
        <div className="p-6">
          <CampaignForm onClose={onClose} />
        </div>
      </div>
    </div>
  );
};

export default CampaignFormModal;