import React, { useEffect, useState, useRef } from 'react';
import { FileSpreadsheet, Search, Loader2, Upload, Download, Check, X, List } from 'lucide-react';
import * as API from '../../api';
import { PageTitle } from '../../../_metronic/layout/core';
import { Modal } from 'react-bootstrap';
import UploadModal from '../modals/UploadListModal';
import ConfirmModal from '../modals/ConfirmModal';
import { GetPermissions } from '../../modules/auth/core/Auth';
import { StatsCard } from './StatsCard';
import { SearchInput } from './SearchInput';
import { FilterBar } from './FilterBar';
import { ListTable } from './ListTable';

const CHANGE_TO: Record<string, string> = {
  "CENTRO NORTE": "CENTRO-NORTE",
  "CENTRO OESTE": "CENTRO-OESTE",
  "SAO PAULO": "SÃO PAULO",
};

type Props = {
  className?: string;
};

const ListScreen: React.FC<Props> = () => {
  const permissions = GetPermissions();
  const [message, setMessage] = useState('');
  const [confirmModal, showConfirmModal] = useState(false);
  const [selectedList, setSelectedList] = useState<any>(null);
  const [uploadModal, showUploadModal] = useState(false);
  const [uploadPromise, setUploadPromise] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState<any>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchData, setSearchData] = useState<any>([]);
  const [resultsData, setResultsData] = useState<any>([]);
  const fileInputRef = useRef<HTMLInputElement>(null);

  // Filter states
  const [regionais, setRegionais] = useState<any>([]);
  const [selectedRegional, setSelectedRegional] = useState({ value: 'all', label: 'Regionais' });
  const [tamanhos, setTamanhos] = useState<any>([]);
  const [selectedTamanho, setSelectedTamanho] = useState({ value: 'all', label: 'Tamanhos' });
  const [bus, setBUs] = useState<any>([]);
  const [selectedBU, setSelectedBU] = useState({ value: 'all', label: 'BUs' });

  useEffect(() => {
    Promise.all([
      getRegionais(),
      getTamanhos(),
      getBUs(),
      getLists()
    ]);
  }, []);

  useEffect(() => {
    showConfirmModal(message !== '');
  }, [message]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      searchRequest();
    }, 150);

    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  useEffect(() => {
    if (searchTerm === '') {
      setResultsData(filterData(data));
    } else {
      setResultsData(filterData(searchData));
    }
  }, [data, searchData, selectedTamanho, selectedRegional, selectedBU]);

  useEffect(() => {
    showUploadModal(uploadPromise !== null);
  }, [uploadPromise]);

  const normalizeRegional = (regional: string) => {
    const upperRegional = regional.toUpperCase();
    return CHANGE_TO[upperRegional] || upperRegional;
  };

  const searchRequest = async () => {
    if (!searchTerm) {
      setSearchData([]);
      return;
    }

    try {
      const response = await API.searchList(searchTerm);
      if (response.success) {
        const normalizedData = response.data.map((item: any) => ({
          ...item,
          Regional: normalizeRegional(item.Regional)
        }));
        setSearchData(normalizedData);
      }
    } catch (error) {
      console.error('Error searching lists:', error);
    }
  };

  const filterData = (data: any[]) => {
    return data.filter((item: any) => {
      const matchesTamanho = selectedTamanho.value === 'all' || selectedTamanho.label === item.Tamanho;
      const matchesRegional = selectedRegional.value === 'all' || selectedRegional.label === item.Regional;
      const matchesBU = selectedBU.value === 'all' || selectedBU.label === item.BU;
      return matchesTamanho && matchesRegional && matchesBU;
    });
  };

  const getLists = async () => {
    setIsLoading(true);
    try {
      const response = await API.getLists();
      const normalizedData = response.data.map((item: any) => ({
        ...item,
        Regional: normalizeRegional(item.Regional)
      }));
      setData(normalizedData);
    } catch (error) {
      console.error('Error loading lists:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const getRegionais = async () => {
    try {
      const response = await API.getRegionalList();
      const normalizedData = response.data.map((item: any) => ({
        ...item,
        NomeRegional: item.NomeRegional.toUpperCase()
      }));
      setRegionais(normalizedData);
    } catch (error) {
      console.error('Error loading regionais:', error);
    }
  };

  const getTamanhos = async () => {
    try {
      const response = await API.getTamanhoList();
      setTamanhos(response.data);
    } catch (error) {
      console.error('Error loading tamanhos:', error);
    }
  };

  const getBUs = async () => {
    try {
      const response = await API.getBUList();
      setBUs(response.data);
    } catch (error) {
      console.error('Error loading BUs:', error);
    }
  };

  const updateStatus = async (list: any, status: boolean) => {
    if (list.is_actived === status) return;

    try {
      const response = await API.updateListStatus(list, status);
      if (response.success) {
        const updateItem = (items: any[]) =>
          items.map((item) =>
            item.IDLista === list.IDLista
              ? { ...item, is_actived: status }
              : item
          );

        setData(updateItem(data));
        setSearchData(updateItem(searchData));
      }
    } catch (error) {
      console.error('Error updating list status:', error);
    }
  };

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const promise = API.uploadList(file);
      setUploadPromise(promise);
    }
  };

  return (
    <div className="min-h-screen bg-gray-50">
      <div className="max-w mx-auto px-4 sm:px-6 lg:px-8">
        {/* Header */}
        <div className="mb-8">
          <div className="flex items-center justify-between">
            <PageTitle>Listas</PageTitle>
            {permissions?.some(feature => feature.name.includes('cashback-listas-add')) && (
              <div className="flex items-center gap-4">
                <button
                  onClick={() => fileInputRef.current?.click()}
                  className="inline-flex items-center px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
                >
                  <Upload className="w-5 h-5 mr-2" />
                  Cadastrar Listas
                </button>
                <a
                  href="https://sadataliteracyexternal.blob.core.windows.net/insight-experience/static/b96ef759-fc65-4631-8cd1-f3705d29aba6.xlsx"
                  className="inline-flex items-center px-4 py-2 bg-gray-100 text-gray-700 rounded-lg hover:bg-gray-200 transition-colors"
                >
                  <Download className="w-5 h-5 mr-2" />
                  Baixar modelo
                </a>
                <input
                  ref={fileInputRef}
                  type="file"
                  accept=".xlsx"
                  className="hidden"
                  onChange={handleFileChange}
                />
              </div>
            )}
          </div>

          {!isLoading && (
            <div className="mt-6 grid gap-6 md:grid-cols-2 lg:grid-cols-3">
              <StatsCard
                title="Total de Listas"
                value={data.length}
                description="Listas cadastradas"
                icon={<List className="w-6 h-6" />}
              />
              {searchTerm && (
                <StatsCard
                  title="Resultados da Busca"
                  value={resultsData.length}
                  description="Listas encontradas"
                  icon={<Search className="w-6 h-6" />}
                />
              )}
            </div>
          )}
        </div>

        {/* Search and Filters */}
        <div className="space-y-6 mb-6">
          <SearchInput
            value={searchTerm}
            onChange={setSearchTerm}
            placeholder="Digite ID da Lista ou Nome..."
          />

          <FilterBar
            tamanhos={tamanhos}
            selectedTamanho={selectedTamanho}
            onTamanhoChange={setSelectedTamanho}
            regionais={regionais}
            selectedRegional={selectedRegional}
            onRegionalChange={setSelectedRegional}
            bus={bus}
            selectedBU={selectedBU}
            onBUChange={setSelectedBU}
          />
        </div>

        {/* Main Content */}
        <div className="bg-white rounded-xl shadow-sm border border-gray-200 overflow-hidden">
          {isLoading ? (
            <div className="flex flex-col items-center justify-center py-12">
              <Loader2 className="w-8 h-8 text-blue-500 animate-spin" />
              <p className="mt-4 text-gray-500">Carregando dados...</p>
            </div>
          ) : !resultsData.length ? (
            <div className="flex flex-col items-center justify-center py-12">
              <div className="bg-gray-100 rounded-full p-3">
                <List className="w-8 h-8 text-gray-400" />
              </div>
              <p className="mt-4 text-gray-500">Nenhuma lista encontrada</p>
            </div>
          ) : (
            <ListTable
              data={resultsData}
              permissions={permissions}
              onUpdateStatus={(list, status) => {
                setMessage(`Deseja ${status ? 'ativar' : 'desativar'} essa lista?`);
                setSelectedList(list);
              }}
            />
          )}
        </div>
      </div>

      {uploadModal && (
        <div className="fixed inset-0 bg-gray-900/50 backdrop-blur-sm flex items-center justify-center p-4 z-50">
        <UploadModal
          promise={uploadPromise}
          onClose={(update = false) => {
            setUploadPromise(null);
            if (update) getLists();
          }}
        />
        </div>)}

      {confirmModal && (
        <div className="fixed inset-0 bg-gray-900/50 backdrop-blur-sm flex items-center justify-center p-4 z-50">
        <ConfirmModal
          title={selectedList?.NomeLista}
          message={message}
          onClose={(value) => {
            if (value) {
              updateStatus(selectedList, !selectedList.is_actived);
            }
            setSelectedList(null);
            setMessage('');
          }}
        />
        </div>)}
    </div>
  );
};

export default ListScreen;