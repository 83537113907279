import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {HomeWrapper} from '../pages/home/HomeWrapper'
import {MenuTestPage} from '../pages/MenuTestPage'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'
import Dashboard from '../pages/dashboard/Dashboard'
import Users from '../pages/users/Users'
import CompanyTabs from '../pages/company/CompanyTabs'
import Companys from '../pages/company/Companys'
import GeneralMenu from '../pages/generalmenu/GeneralMenu'
import Support from '../pages/support/Support'
import { SupportWrapper } from '../pages/support/SupportWrapper'
import SupportList from '../pages/support/SupportList'
import SupportUserPage from '../pages/support/SupportUserPage'
import ModalViewTicket from '../pages/support/ModalViewTicket'
import NPSEditor from '../pages/nps/NPSEditor'

import API from '../pages/api/API'

import { getUserData, isSuperAdmin } from '../modules/auth'
import StoreScreen from '../pages/stores/StoreScreen'
import VoucherScreen from '../pages/management/VoucherScreen'
import InvestigationScreen from '../pages/investigation/InvestigationScreen'
import ReportScreen from '../pages/reports/ReportScreen'
import ListScreen from '../pages/lists/ListScreen'
import BUScreen from '../pages/lists/bu/BUScreen'
import RegionalScreen from '../pages/lists/regional/RegionalScreen'
import TamanhoScreen from '../pages/lists/tamanho/TamanhoScreen'
import PriceScreen from '../pages/lists/prices/PriceScreen'
import ReportListScreen from '../pages/reports/ReportListScreen'
import PositivacaoReportCurrent from '../pages/reports/positivacao/PositivacaoReportCurrent'
import ReportPositivacao from '../pages/reports/positivacao/ReportPositivacao'
import ReportAtivacao from '../pages/reports/ativacao/ReportAtivacao'
import ReportLojistas from '../pages/reports/lojistas/ReportLojistas'
import ReportCupons from '../pages/reports/cupons/ReportCupons'
import ReportApuracao from '../pages/reports/apuracao/ReportApuracao'
import LogsScreen from '../pages/logs/LogsScreen'
import AIScreen from '../pages/ai/AIScreen'
import AlertScreen from '../pages/alerts/AlertScreen'
import PermissionsScreen from '../pages/permissions/PermissionsScreen'
import HomePage from '../pages/home/HomePage'

import NPSList from '../pages/nps/NPSList'
import NPSCreator from '../pages/nps/NPSCreator'

import CampaignHistory from '../pages/campaign-simulator/components/CampaignHistory'

const PrivateRoutes = () => {
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))
  

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/home' />} />
        {/* Pages */}
        <Route path='home' element={<HomePage />} />
        <Route path='/' element={<HomePage />} />
        <Route path='users' element={<Users />} />
        <Route path='companys' element={<CompanyTabs />} />
        
        <Route path='lojas' element={<StoreScreen />} />
        
        <Route path='listas' element={<ListScreen />} />
        <Route path='bus' element={<BUScreen />} />
        <Route path='regionais' element={<RegionalScreen />} />
        <Route path='tamanhos' element={<TamanhoScreen />} />
        <Route path='precos' element={<PriceScreen />} />

        <Route path='cupons' element={<VoucherScreen />} />
        <Route path='apuracao' element={<InvestigationScreen />} />

        <Route path='relatorios/lojistas' element={<ReportLojistas />} />
        <Route path='relatorios/ativacao' element={<ReportAtivacao />} />
        <Route path='relatorios/positivacao' element={<ReportPositivacao />} />
        <Route path='relatorios/cupons' element={<ReportCupons />} />
        <Route path='relatorios/apuracao' element={<ReportApuracao />} />
        
        <Route path='logs' element={<LogsScreen />} />
        <Route path='alerts' element={<AlertScreen />} />
        <Route path='ai' element={<AIScreen />} />
        <Route path='permissions' element={<PermissionsScreen />} />
        
        <Route path='support' element={<SupportWrapper />} />
        <Route path='support/:ticket' element={<SupportWrapper />} />

        <Route path='nps' element={<NPSList />} />
        <Route path='nps/create' element={<NPSCreator npsId={null} onBack={() => {}} />} />
        <Route path='nps/edit/:id' element={<NPSCreator npsId={null} onBack={() => {}} />} />

        <Route path='campaigns' element={<CampaignHistory/>} />

        {/* Lazy Modules */}
        <Route
          path='profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path='widgets/*'
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/chat/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/user-management/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--kt-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
