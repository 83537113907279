import React from 'react';
import { CheckCircle, AlertCircle, Clock } from 'lucide-react';
import { CampaignStatus } from '../types/campaign';

interface StatusBadgeProps {
  status: CampaignStatus;
}

const StatusBadge: React.FC<StatusBadgeProps> = ({ status }) => {
  const getStatusConfig = () => {
    switch (status) {
      case 'COMPLETED':
        return {
          classes: 'bg-green-50 text-green-700 border-green-200 ring-green-600/20',
          icon: <CheckCircle className="h-4 w-4 text-green-500 mr-1.5" />,
          text: 'Finalizada'
        };
      case 'PROCESSING':
        return {
          classes: 'bg-blue-50 text-blue-700 border-blue-200 ring-blue-600/20',
          icon: <Clock className="h-4 w-4 text-blue-500 mr-1.5 animate-spin" />,
          text: 'Em Processamento'
        };
      case 'ERROR':
        return {
          classes: 'bg-red-50 text-red-700 border-red-200 ring-red-600/20',
          icon: <AlertCircle className="h-4 w-4 text-red-500 mr-1.5" />,
          text: 'Erro'
        };
      default:
        return {
          classes: 'bg-gray-50 text-gray-700 border-gray-200 ring-gray-600/20',
          icon: null,
          text: status
        };
    }
  };

  const config = getStatusConfig();

  return (
    <span className={`inline-flex items-center px-2.5 py-1.5 rounded-full text-sm font-medium border ring-1 ring-inset ${config.classes}`}>
      {config.icon}
      {config.text}
    </span>
  );
};

export default StatusBadge;