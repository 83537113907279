import React, { useState, useEffect } from 'react';
import { X, Upload, AlertTriangle, CheckCircle, Loader2 } from 'lucide-react';
import * as API from '../../api';
import clsx from 'clsx';

type Props = {
  onClose: (updated?: boolean) => void;
  promise: any;
  error?: boolean;
};

const errors: Record<string, string> = {
  'exists': 'Já Cadastrado',
  'not-found': 'CNPJ não reconhecido',
  'name': 'CNPJ não reconhecido'
};

const UploadStoreModal: React.FC<Props> = ({ onClose, promise, error: propError }) => {
  const [loading, setLoading] = useState(true);
  const [addLoading, setAddLoading] = useState(false);
  const [error, setError] = useState(false);
  const [resultsData, setResultsData] = useState<any[]>([]);
  const [hasErrors, setHasErrors] = useState(false);
  const [incorrectCount, setIncorrectCount] = useState(0);
  const [willUpdateCount, setWillUpdateCount] = useState(0);

  useEffect(() => {
    setError(false);
    setLoading(true);
    
    if (promise) {
      promise
        .then((result: any) => {
          setResultsData(result.data);
          setIncorrectCount(result.data.filter((item: any) => 
            Array.isArray(item.errors) && item.errors.length !== 0
          ).length);
          setWillUpdateCount(result.data.filter((item: any) => item.hasChanges).length);
        })
        .catch(() => setError(true))
        .finally(() => setLoading(false));
    }
  }, [promise]);

  useEffect(() => {
    if (!resultsData?.length) {
      setHasErrors(false);
      return;
    }
    setHasErrors(resultsData.some(item => item.errors?.length > 0));
  }, [resultsData]);

  const addItems = async () => {
    setAddLoading(true);
    try {
      await API.updateStores(resultsData);
      onClose(true);
    } catch (error) {
      console.error('Error updating stores:', error);
      setAddLoading(false);
    }
  };

  const renderLoadingState = () => (
    <div className="flex flex-col items-center justify-center py-16">
      <Loader2 className="w-12 h-12 text-blue-500 animate-spin" />
      <p className="mt-6 text-lg text-gray-600">
        {addLoading ? 'Atualizando registros...' : 'Validando dados...'}
      </p>
    </div>
  );

  const renderErrorState = () => (
    <div className="flex flex-col items-center justify-center py-16">
      <div className="p-3 bg-red-100 rounded-full">
        <AlertTriangle className="w-12 h-12 text-red-500" />
      </div>
      <p className="mt-6 text-lg text-red-600">Houve um problema com seu arquivo</p>
    </div>
  );

  return (
    <div className="fixed inset-0 z-50 overflow-y-auto">
      <div className="flex min-h-screen items-center justify-center p-4">
        {/* Backdrop */}
        <div 
          className="fixed inset-0 bg-black/50 backdrop-blur-sm"
          onClick={() => !loading && !addLoading && onClose()}
        />

        {/* Modal Content */}
        <div className="relative bg-white rounded-2xl shadow-xl max-w-5xl w-full overflow-hidden">
          {/* Header */}
          <div className="px-8 py-6 bg-gradient-to-r from-blue-600 to-blue-700 flex items-center justify-between">
            <div className="flex items-center gap-4">
              <div className="p-3 bg-white/10 rounded-xl">
                <Upload className="w-7 h-7 text-white" />
              </div>
              <h3 className="text-xl font-semibold text-white">
                Validação e Cadastro de Lojas
              </h3>
            </div>
            {!loading && !addLoading && !propError && !error && (
              <button
                onClick={() => onClose()}
                className="p-2.5 text-white/80 hover:text-white hover:bg-white/10 rounded-lg transition-colors"
              >
                <X className="w-6 h-6" />
              </button>
            )}
          </div>

          {/* Content */}
          <div className="max-h-[75vh] overflow-y-auto">
            {(loading || addLoading) ? (
              renderLoadingState()
            ) : propError || error ? (
              renderErrorState()
            ) : (
              <div className="p-8">
                <div className="bg-white rounded-xl border border-gray-200 overflow-hidden">
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                      <tr>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">ID Lojista</th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">CNPJ</th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Razão Social</th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Status</th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Problemas</th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200">
                      {/* Invalid Items */}
                      {resultsData.filter(item => item.errors?.length).map((item) => (
                        <tr key={`error-${item.IDLista}`} className="bg-red-50">
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{item.IDLojista}</td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{item.CNPJ}</td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{item.RazaoSocial}</td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <span className={clsx(
                              "inline-flex rounded-full px-2 py-1 text-xs font-semibold",
                              item.is_actived ? "bg-green-100 text-green-800" : "bg-red-100 text-red-800"
                            )}>
                              {item.is_actived ? 'Participante' : 'Não Participante'}
                            </span>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <div className="text-sm text-red-600">
                              {item.errors.map((error: string) => errors[error]).join(', ')}
                            </div>
                          </td>
                        </tr>
                      ))}

                      {/* Valid Items with Changes */}
                      {resultsData.filter(item => item.hasChanges && !item.errors?.length).map((item) => (
                        <tr key={`valid-${item.IDLista}`} className="bg-green-50">
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{item.IDLojista}</td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{item.CNPJ}</td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{item.RazaoSocial}</td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <span className="inline-flex items-center gap-1 rounded-full bg-blue-100 px-2 py-1 text-xs font-semibold text-blue-700">
                              <span className="h-1.5 w-1.5 rounded-full bg-blue-700" />
                              {item.is_actived ? 'Alterado para Participante' : 'Alterado para Não Participante'}
                            </span>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-400">-</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>

                {/* Summary */}
                <div className="mt-8 space-y-4">
                  {hasErrors ? (
                    resultsData.length === incorrectCount ? (
                      <div className="flex items-center gap-3 text-red-600">
                        <AlertTriangle className="w-6 h-6" />
                        <p className="text-lg font-medium">Nenhum registro será atualizado</p>
                      </div>
                    ) : (
                      <div className="space-y-4">
                        <div className="flex items-center gap-3 text-red-600">
                          <AlertTriangle className="w-6 h-6" />
                          <p className="text-lg font-medium">
                            {incorrectCount} registro(s) não serão atualizados
                          </p>
                        </div>
                        <div className="flex items-center gap-3 text-green-600">
                          <CheckCircle className="w-6 h-6" />
                          <p className="text-lg font-medium">
                            {willUpdateCount} registro(s) serão atualizados
                          </p>
                        </div>
                      </div>
                    )
                  ) : (
                    <div className="flex items-center gap-3 text-green-600">
                      <CheckCircle className="w-6 h-6" />
                      <p className="text-lg font-medium">
                        Todos os {resultsData.length} registros estão corretos
                      </p>
                    </div>
                  )}
                </div>

                {/* Actions */}
                {resultsData.length !== incorrectCount && (
                  <div className="mt-8 flex justify-center gap-4">
                    <button
                      onClick={() => onClose()}
                      className="px-6 py-3 bg-gray-100 text-gray-700 rounded-lg hover:bg-gray-200 transition-colors"
                    >
                      Cancelar
                    </button>
                    <button
                      onClick={addItems}
                      disabled={addLoading}
                      className="px-6 py-3 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors disabled:opacity-50 disabled:cursor-not-allowed flex items-center gap-2"
                    >
                      {addLoading && <Loader2 className="w-5 h-5 animate-spin" />}
                      {addLoading ? 'Atualizando...' : 'Atualizar Registros'}
                    </button>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UploadStoreModal;