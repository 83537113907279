import { Store } from 'lucide-react';
import clsx from 'clsx';

type VoucherTableProps = {
  data: any[];
  onViewStores: (voucher: any) => void;
};

const formatDate = (dateString: string): string => {
  const date = new Date(dateString);
  return new Intl.DateTimeFormat('pt-BR', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  }).format(date);
};

export const VoucherTable = ({ data, onViewStores }: VoucherTableProps) => (
  <div className="overflow-x-auto">
    <table className="min-w-full divide-y divide-gray-200">
      <thead className="bg-gray-50">
        <tr>
          <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            ID Cupom
          </th>
          <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            Descrição
          </th>
          <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            Data Início
          </th>
          <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            Data Término
          </th>
          <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            Lojas Participantes
          </th>
          <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
            Detalhes
          </th>
        </tr>
      </thead>
      <tbody className="bg-white divide-y divide-gray-200">
        {data.map((voucher) => (
          <tr
            key={voucher.IDVoucher}
            className={clsx(
              'hover:bg-gray-50 transition-colors',
              !voucher.is_actived && 'bg-red-50'
            )}
          >
            <td className="px-6 py-4 whitespace-nowrap">
              <span className="text-sm font-medium text-gray-900">
                {voucher.IDVoucher}
              </span>
            </td>
            <td className="px-6 py-4">
              <span className="text-sm text-gray-900">
                {voucher.DescricaoCupom}
              </span>
            </td>
            <td className="px-6 py-4 whitespace-nowrap">
              <span className={clsx(
                'inline-flex rounded-full px-3 py-1 text-xs font-semibold',
                new Date(voucher.DataInicioVigenciaCupom) <= new Date()
                  ? 'bg-green-100 text-green-800'
                  : 'bg-yellow-100 text-yellow-800'
              )}>
                {formatDate(voucher.DataInicioVigenciaCupom)}
              </span>
            </td>
            <td className="px-6 py-4 whitespace-nowrap">
              <span className={clsx(
                'inline-flex rounded-full px-3 py-1 text-xs font-semibold',
                new Date(voucher.DataFimVigenciaCupom) < new Date()
                  ? 'bg-red-100 text-red-800'
                  : 'bg-green-100 text-green-800'
              )}>
                {formatDate(voucher.DataFimVigenciaCupom)}
              </span>
            </td>
            <td className="px-6 py-4 whitespace-nowrap">
              <span className={clsx(
                'inline-flex rounded-full px-3 py-1 text-xs font-semibold',
                voucher.QuantidadeIDLojista > 0
                  ? 'bg-blue-100 text-blue-800'
                  : 'bg-gray-100 text-gray-800'
              )}>
                {voucher.QuantidadeIDLojista} lojas
              </span>
            </td>
            <td className="px-6 py-4 whitespace-nowrap text-right">
              {voucher.QuantidadeIDLojista > 0 && (
                <button
                  onClick={() => onViewStores(voucher)}
                  className="p-2 text-blue-600 hover:text-blue-900 hover:bg-blue-50 rounded-lg transition-colors"
                >
                  <Store className="w-5 h-5" />
                </button>
              )}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
);