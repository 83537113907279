import React, { useState } from 'react';
import FormField from './FormField';
import Select from './Select';
import ProcessingMessage from './ProcessingMessage';
import { formatCurrency } from '../utils/currency';
import { CAMPAIGN_CLASSES, BUSINESS_UNITS, REGIONS } from '../utils/constants';
import { Campaign } from '../types/campaign';
import * as API from '../../../../app/api';

interface CampaignFormProps {
  onClose: () => void;
}

const CampaignForm: React.FC<CampaignFormProps> = ({ onClose }) => {
  const [formData, setFormData] = useState({
    name: '',
    campaignClass: '',
    businessUnit: '',
    region: '',
    investment: '',
    eanQuantity: '',
    averageDiscount: '',
    duration: '',
  });
  const [isProcessing, setIsProcessing] = useState(false);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    let formattedValue = value;

    if (name === 'investment') {
      formattedValue = value.replace(/\D/g, '');
    }

    setFormData(prev => ({ ...prev, [name]: formattedValue }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsProcessing(true);
    
    const response = await API.newCampaign(formData);
    await new Promise(resolve => setTimeout(resolve, 2000));
    onClose();
  };

  if (isProcessing) {
    return <ProcessingMessage />;
  }

  return (
    <form onSubmit={handleSubmit} className="space-y-8">
      <div className="bg-gray-50 rounded-xl p-6">
        <h3 className="text-lg font-semibold text-gray-900 mb-6">Informações Básicas</h3>
        <div className="space-y-6">
          <FormField 
            label="Nome da Campanha"
            required
            tooltip="Nome da campanha"
          >
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
              required
              className="block w-full rounded-lg border border-gray-300 px-4 py-2.5 focus:border-blue-500 focus:ring-1 focus:ring-blue-500 text-gray-900 text-sm transition-colors duration-200"
              placeholder="Digite o nome da campanha"
            />
          </FormField>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <FormField 
              label="Classe da Campanha"
              required
              tooltip="Selecione entre: Rebaixa, Lista Sugerida, A+B ou Cupom"
            >
              <Select
                name="campaignClass"
                value={formData.campaignClass}
                onChange={handleInputChange}
                required
                options={CAMPAIGN_CLASSES}
                placeholder="Selecione uma classe"
              />
            </FormField>

            <FormField 
              label="BU"
              required
              tooltip="Selecione a unidade de negócio"
            >
              <Select
                name="businessUnit"
                value={formData.businessUnit}
                onChange={handleInputChange}
                required
                options={BUSINESS_UNITS}
                placeholder="Selecione uma BU"
              />
            </FormField>
          </div>

          <FormField 
            label="Região"
            required
            tooltip="Selecione a região de atuação"
          >
            <Select
              name="region"
              value={formData.region}
              onChange={handleInputChange}
              required
              options={REGIONS}
              placeholder="Selecione uma região"
            />
          </FormField>
        </div>
      </div>

      <div className="bg-gray-50 rounded-xl p-6">
        <h3 className="text-lg font-semibold text-gray-900 mb-6">Detalhes da Campanha</h3>
        <div className="space-y-6">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <FormField 
              label="Investimento"
              required
              tooltip="Valor do investimento em reais"
            >
              <input
                type="text"
                name="investment"
                value={formData.investment ? formatCurrency(formData.investment) : ''}
                onChange={handleInputChange}
                required
                className="block w-full rounded-lg border border-gray-300 px-4 py-2.5 focus:border-blue-500 focus:ring-1 focus:ring-blue-500 text-gray-900 text-sm transition-colors duration-200"
                placeholder="R$ 0,00"
              />
            </FormField>

            <FormField 
              label="Quantidade de EAN"
              required
              tooltip="Número de produtos participantes"
            >
              <input
                type="number"
                name="eanQuantity"
                value={formData.eanQuantity}
                onChange={handleInputChange}
                required
                min="1"
                className="block w-full rounded-lg border border-gray-300 px-4 py-2.5 focus:border-blue-500 focus:ring-1 focus:ring-blue-500 text-gray-900 text-sm transition-colors duration-200"
                placeholder="Digite a quantidade"
              />
            </FormField>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <FormField 
              label="Desconto Médio"
              tooltip="Percentual médio de desconto"
            >
              <input
                type="number"
                name="averageDiscount"
                value={formData.averageDiscount}
                onChange={handleInputChange}
                min="0"
                max="100"
                className="block w-full rounded-lg border border-gray-300 px-4 py-2.5 focus:border-blue-500 focus:ring-1 focus:ring-blue-500 text-gray-900 text-sm transition-colors duration-200"
                placeholder="Digite o percentual"
              />
            </FormField>

            <FormField 
              label="Duração (semanas)"
              tooltip="Duração da campanha em semanas"
            >
              <input
                type="number"
                name="duration"
                value={formData.duration}
                onChange={handleInputChange}
                min="1"
                className="block w-full rounded-lg border border-gray-300 px-4 py-2.5 focus:border-blue-500 focus:ring-1 focus:ring-blue-500 text-gray-900 text-sm transition-colors duration-200"
                placeholder="Digite o número de semanas"
              />
            </FormField>
          </div>
        </div>
      </div>

      <div className="flex justify-end gap-3">
        <button
          type="button"
          onClick={onClose}
          className="px-4 py-2.5 border border-gray-300 rounded-lg text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-colors duration-200"
        >
          Cancelar
        </button>
        <button
          type="submit"
          className="px-4 py-2.5 border border-transparent rounded-lg shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-colors duration-200"
        >
          Criar Campanha
        </button>
      </div>
    </form>
  );
};

export default CampaignForm;