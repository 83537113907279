import { PencilLine } from 'lucide-react';

type EditToggleProps = {
  isEditable: boolean;
  onChange: (checked: boolean) => void;
};

export const EditToggle = ({ isEditable, onChange }: EditToggleProps) => (
  <label className="inline-flex items-center gap-3 cursor-pointer">
    <span className="text-sm font-medium text-gray-700">Alterar Valores</span>
    <div className="relative">
      <input
        type="checkbox"
        checked={isEditable}
        onChange={(e) => onChange(e.target.checked)}
        className="sr-only peer"
      />
      <div className="w-11 h-6 bg-gray-200 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600" />
    </div>
    <PencilLine className={`w-5 h-5 ${isEditable ? 'text-blue-600' : 'text-gray-400'}`} />
  </label>
);