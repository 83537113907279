import React, { useEffect, useState, useRef } from 'react';
import { FileSpreadsheet, Search, Loader2, Upload, Download, Check, X } from 'lucide-react';
import * as API from '../../api';
import { PageTitle } from '../../../_metronic/layout/core';
import { Modal } from 'react-bootstrap';
import VouchersListModal from '../modals/VouchersListModal';
import UploadModal from '../modals/UploadStoreModal';
import ConfirmModal from '../modals/ConfirmModal';
import { GetPermissions } from '../../modules/auth/core/Auth';
import Select from 'react-select';
import { StatsCard } from './StatsCard';
import { SearchInput } from './SearchInput';
import { StoreTable } from './StoreTable';

const filterParticipantesOptions = [
  { value: '', label: 'Todos' },
  { value: 'active', label: 'Participantes' },
  { value: 'inactive', label: 'Não Participantes' }
];

type Props = {
  className?: string;
};

const StoreScreen: React.FC<Props> = () => {
  const permissions = GetPermissions();
  const [message, setMessage] = useState('');
  const [confirmModal, showConfirmModal] = useState(false);
  const [confirmSelectedStore, setConfirmSelectedStore] = useState<any>(null);
  const [uploadModal, showUploadModal] = useState(false);
  const [uploadPromise, setUploadPromise] = useState<any>(null);
  const [voucherModal, showVoucherModal] = useState(false);
  const [companys, setCompanys] = useState<any>([]);
  const [selectedStore, setSelectedStore] = useState<any>(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [counter, setCounter] = useState(0);
  const [searchData, setSearchData] = useState<any>([]);
  const [resultsData, setResultsData] = useState<any>([]);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [filterParticipantes, setFilterParticipantes] = useState(filterParticipantesOptions[0]);

  useEffect(() => {
    loadStores();
  }, []);

  useEffect(() => {
    showConfirmModal(message !== '');
  }, [message]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      searchRequest();
    }, 150);

    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm, filterParticipantes]);

  useEffect(() => {
    if (searchTerm === '' && filterParticipantes.value === '') {
      setResultsData(companys);
    } else {
      setResultsData(searchData);
    }
  }, [companys, searchData]);

  const loadStores = async () => {
    setIsLoading(true);
    try {
      const response = await API.listStores(0);
      setCompanys(response.data);
      setCounter(response.count);
    } catch (error) {
      console.error('Error loading stores:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const searchRequest = async () => {
    if (searchTerm === '' && filterParticipantes.value === '') {
      setSearchData([]);
      return;
    }
    setIsLoading(true);
    try {
      const response = await API.searchStore(searchTerm, filterParticipantes.value);
      if (response.success) setSearchData(response.data);
    } catch (error) {
      console.error('Error searching stores:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const updateStatus = async (company: any, status: boolean) => {
    if (company.is_actived === status) return;
    try {
      const response = await API.updateStoreStatus(company, status);
      if (response.success) {
        const updateCompany = (comp: any) =>
          comp.IDLojista === company.IDLojista
            ? { ...comp, is_actived: status }
            : comp;

        setCompanys(companys.map(updateCompany));
        setSearchData(searchData.map(updateCompany));
      }
    } catch (error) {
      console.error('Error updating store status:', error);
    }
  };

  useEffect(() => {
    showVoucherModal(selectedStore !== null);
  }, [selectedStore]);

  useEffect(() => {
    showUploadModal(uploadPromise !== null);
  }, [uploadPromise]);

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const promise = API.uploadStores(file);
      setUploadPromise(promise);
    }
  };

  return (
    <div className="min-h-screen bg-gray-50">
      <div className="max-w mx-auto px-4 sm:px-6 lg:px-8">
        {/* Header */}
        <div className="mb-8">
          <div className="flex items-center justify-between">
            <PageTitle>Lojas Participantes</PageTitle>
            {permissions?.some(feature => feature.name.includes('cashback-lojas-participantes-add')) && (
              <div className="flex items-center gap-4">
                <button
                  onClick={() => fileInputRef.current?.click()}
                  className="inline-flex items-center px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
                >
                  <Upload className="w-5 h-5 mr-2" />
                  Alterar Status de Lojas
                </button>
                <a
                  href="https://sadataliteracyexternal.blob.core.windows.net/insight-experience/static/52361195-8d27-4df1-8b03-59e389e90506.xlsx"
                  className="inline-flex items-center px-4 py-2 bg-gray-100 text-gray-700 rounded-lg hover:bg-gray-200 transition-colors"
                >
                  <Download className="w-5 h-5 mr-2" />
                  Baixar modelo
                </a>
                <input
                  ref={fileInputRef}
                  type="file"
                  accept=".xlsx"
                  className="hidden"
                  onChange={handleFileChange}
                />
              </div>
            )}
          </div>

          {!isLoading && (
            <div className="mt-6 grid gap-6 md:grid-cols-2 lg:grid-cols-3">
              <StatsCard
                title="Total de Lojas"
                value={counter}
                description="Lojas cadastradas no sistema"
                icon={<FileSpreadsheet className="w-6 h-6" />}
              />
              {searchTerm && (
                <StatsCard
                  title="Resultados da Busca"
                  value={resultsData.length}
                  description="Lojas encontradas com o termo pesquisado"
                  icon={<Search className="w-6 h-6" />}
                />
              )}
            </div>
          )}
        </div>

        {/* Search and Filters */}
        <div className="mb-6 flex flex-col sm:flex-row items-center gap-4">
          <SearchInput
            value={searchTerm}
            onChange={setSearchTerm}
            placeholder="Digite CNPJ ou Razão Social..."
          />
          <Select
            className="w-full sm:w-64"
            value={filterParticipantes}
            options={filterParticipantesOptions}
            onChange={(option) => setFilterParticipantes(option || filterParticipantesOptions[0])}
            placeholder="Filtrar por status"
          />
        </div>

        {/* Main Content */}
        <div className="bg-white rounded-xl shadow-sm border border-gray-200 overflow-hidden">
          {isLoading ? (
            <div className="flex flex-col items-center justify-center py-12">
              <Loader2 className="w-8 h-8 text-blue-500 animate-spin" />
              <p className="mt-4 text-gray-500">Carregando dados...</p>
            </div>
          ) : !resultsData.length ? (
            <div className="flex flex-col items-center justify-center py-12">
              <div className="bg-gray-100 rounded-full p-3">
                <FileSpreadsheet className="w-8 h-8 text-gray-400" />
              </div>
              <p className="mt-4 text-gray-500">Nenhuma loja encontrada</p>
            </div>
          ) : (
            <StoreTable
              data={resultsData}
              permissions={permissions}
              onViewVouchers={setSelectedStore}
              onUpdateStatus={(store, status) => {
                setMessage(`Deseja alterar o status para ${status ? 'PARTICIPANTE' : 'NÃO PARTICIPANTE'}?`);
                setConfirmSelectedStore(store);
              }}
            />
          )}
        </div>
      </div>

      {/* Modals */}
      
      {voucherModal && (
      <div className="fixed inset-0 bg-gray-900/50 backdrop-blur-sm flex items-center justify-center p-4 z-50">
        <VouchersListModal
          store={selectedStore}
          onClose={() => setSelectedStore(null)}
        />
        </div>)}

        {uploadModal && (
      <div className="fixed inset-0 bg-gray-900/50 backdrop-blur-sm flex items-center justify-center p-4 z-50">
        <UploadModal
          promise={uploadPromise}
          onClose={(update = false) => {
            setUploadPromise(null);
            if (update) loadStores();
          }}
        />
        </div>)}

      {confirmModal && (
        <div className="fixed inset-0 bg-gray-900/50 backdrop-blur-sm flex items-center justify-center p-4 z-50">
        <ConfirmModal
          title={confirmSelectedStore?.RazaoSocial}
          message={message}
          onClose={(value) => {
            if (value) {
              updateStatus(confirmSelectedStore, !(confirmSelectedStore.is_actived == '1'));
            }
            setConfirmSelectedStore(null);
            setMessage('');
          }}
        />
        </div>)}
      
    </div>
  );
};

export default StoreScreen;