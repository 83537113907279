import React from 'react';
import { HelpCircle } from 'lucide-react';

interface FormFieldProps {
  label: string;
  required?: boolean;
  tooltip?: string; // Coloquei o tooltip como opcional
  children: React.ReactNode;
}

const FormField: React.FC<FormFieldProps> = ({ label, required, tooltip, children }) => {
  return (
    <div className="relative">
      <div className="flex items-center gap-2 mb-1">
        <label className="block text-sm font-medium text-gray-700">
          {label} {required && '*'}
        </label>
        {tooltip && (
          <div className="relative group">
            <HelpCircle className="h-4 w-4 text-gray-400 cursor-help" />
            <div className="absolute left-1/2 transform -translate-x-1/2 bottom-full mb-2 px-3 py-2 bg-gray-900 text-white text-sm rounded-lg whitespace-nowrap z-10 hidden group-hover:block">
              {tooltip}
              <div className="absolute -bottom-1 left-1/2 transform -translate-x-1/2 w-2 h-2 bg-gray-900 rotate-45"></div>
            </div>
          </div>
        )}
      </div>
      {children}
    </div>
  );
};

export default FormField;