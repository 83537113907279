import React, { useEffect, useRef } from 'react';
import { X } from 'lucide-react';
import { CampaignStatus } from '../types/campaign';

interface StatusFilterProps {
  selectedStatus: CampaignStatus | null;
  onStatusChange: (status: CampaignStatus | null) => void;
  onClose: () => void;
}

const StatusFilter: React.FC<StatusFilterProps> = ({ selectedStatus, onStatusChange, onClose }) => {
  const statuses: CampaignStatus[] = ['COMPLETED', 'PROCESSING', 'ERROR'];
  const filterRef = useRef<HTMLDivElement>(null);
  
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (filterRef.current && !filterRef.current.contains(event.target as Node)) {
        onClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [onClose]);

  return (
    <div ref={filterRef} className="absolute right-0 top-12 mt-2 w-56 bg-white rounded-lg shadow-xl z-10 border border-gray-200 ring-1 ring-black ring-opacity-5">
      <div className="flex justify-between items-center p-3 border-b border-gray-200">
        <h3 className="text-sm font-medium text-gray-700">Filtrar por Status</h3>
        <button 
          onClick={onClose}
          className="text-gray-400 hover:text-gray-500 transition-colors duration-200"
        >
          <X className="h-4 w-4" />
        </button>
      </div>
      <div className="p-3 space-y-2.5">
        {statuses.map((status) => (
          <label key={status} className="flex items-center space-x-2 cursor-pointer">
            <input
              type="radio"
              checked={selectedStatus === status}
              onChange={() => onStatusChange(status)}
              className="h-4 w-4 text-blue-600 border-gray-300 focus:ring-blue-500"
            />
            <span className="text-sm text-gray-700">
              {status === 'COMPLETED' && 'Finalizada'}
              {status === 'PROCESSING' && 'Em Processamento'}
              {status === 'ERROR' && 'Erro'}
            </span>
          </label>
        ))}
        <label className="flex items-center space-x-2 cursor-pointer">
          <input
            type="radio"
            checked={selectedStatus === null}
            onChange={() => onStatusChange(null)}
            className="h-4 w-4 text-blue-600 border-gray-300 focus:ring-blue-500"
          />
          <span className="text-sm text-gray-700">Todos</span>
        </label>
      </div>
    </div>
  );
}

export default StatusFilter;