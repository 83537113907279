import { Building2 } from 'lucide-react';

type BUTableProps = {
  data: any[];
};

export const BUTable = ({ data }: BUTableProps) => (
  <div className="overflow-x-auto">
    <table className="min-w-full divide-y divide-gray-200">
      <thead className="bg-gray-50">
        <tr>
          <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider text-center">
            Nome da BU
          </th>
        </tr>
      </thead>
      <tbody className="bg-white divide-y divide-gray-200">
        {data.map((bu) => (
          <tr key={bu.IDBu} className="hover:bg-gray-50 transition-colors justify-center">
            <td className="px-6 py-4 whitespace-nowrap">
              <div className="flex items-center justify-center">
                <div className="p-2 bg-blue-50 rounded-lg mr-3">
                  <Building2 className="w-5 h-5 text-blue-600" />
                </div>
                <span className="text-sm font-medium text-gray-900">
                  {bu.GrupoFamilia}
                </span>
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
);