import React, { useEffect, useState, useRef } from 'react';
import { DollarSign, Save, X, AlertTriangle, Loader2, PencilRuler } from 'lucide-react';
import * as API from '../../../api';
import { PageTitle } from '../../../../_metronic/layout/core';
import { Modal } from 'react-bootstrap';
import ConfirmModal from '../../modals/ConfirmModal';
import { GetPermissions } from '../../../modules/auth/core/Auth';
import { StatsCard } from './StatsCard';
import { PriceTable } from './PriceTable';
import { EditToggle } from './EditToggle';

type Props = {
  className?: string;
};

const PriceScreen: React.FC<Props> = () => {
  const permissions = GetPermissions();
  const [message, setMessage] = useState('');
  const [confirmModal, showConfirmModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState<any>([]);
  const [tamanhos, setTamanhos] = useState<string[]>([]);
  const [quantities, setQuantities] = useState<number[]>([]);
  const [isEditable, setIsEditable] = useState(false);
  const [editedValues, setEditedValues] = useState<Map<number, string>>(new Map());
  const originalValuesRef = useRef<Map<number, string>>(new Map());

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    showConfirmModal(message !== '');
  }, [message]);

  const getData = async () => {
    setIsLoading(true);
    try {
      const response = await API.getPrecoList();
      const sizes: string[] = [];
      const qtySet = new Set<number>();
      const dataMap = new Map<number, any>();

      response.data.forEach((item: any) => {
        if (!sizes.includes(item.TamanhoLista)) {
          sizes.push(item.TamanhoLista);
        }
        qtySet.add(item.Quantidade);
        if (!dataMap.has(item.Quantidade)) {
          dataMap.set(item.Quantidade, []);
        }
        dataMap.get(item.Quantidade).push(item);
        originalValuesRef.current.set(item.IDPreco, item.value.toString());
      });

      const sortedQuantities = Array.from(qtySet).sort((a, b) => a - b);
      
      setTamanhos(sizes);
      setQuantities(sortedQuantities);
      setData(sortedQuantities.map(qty => dataMap.get(qty)));
    } catch (error) {
      console.error('Error loading prices:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSwitchChange = (checked: boolean) => {
    setIsEditable(checked);
    if (!checked) {
      setEditedValues(new Map());
    }
  };

  const handleValueChange = (id: number, newValue: string) => {
    const originalValue = originalValuesRef.current.get(id);
    const updatedValues = new Map(editedValues);

    if (newValue === originalValue) {
      updatedValues.delete(id);
    } else {
      updatedValues.set(id, newValue);
    }
    setEditedValues(updatedValues);
  };

  const hasChanges = () => {
    return Array.from(editedValues).some(([id, value]) => {
      const originalItem = data.flat().find((item: any) => item.IDPreco === id);
      return originalItem && originalItem.value !== value;
    });
  };

  const handleConfirm = () => {
    setMessage('Ao continuar, os novos valores já contarão para o mês vigente. Tem certeza que deseja confirmar?');
  };

  const updateData = async () => {
    try {
      const arrayData = Array.from(editedValues, ([id, value]) => ({
        id,
        value: parseInt(value)
      }));
      await API.updatePrices({ data: arrayData });
      setIsEditable(false);
      getData();
    } catch (error) {
      console.error('Error updating prices:', error);
    }
  };

  return (
    <div className="min-h-screen bg-gray-50 py-8">
      <div className="max-w mx-auto px-4 sm:px-6 lg:px-8">
        {/* Header */}
        <div className="mb-8">
          <div className="flex items-center justify-between">
            <PageTitle>Tabela de Valores</PageTitle>
            {permissions?.some(feature => feature.name.includes('cashback-tabela-valores-edit')) && (
              <EditToggle isEditable={isEditable} onChange={handleSwitchChange} />
            )}
          </div>

          {!isLoading && (
            <div className="mt-6 grid gap-6 md:grid-cols-2 lg:grid-cols-3">
              <StatsCard
                title="Total de Tamanhos"
                value={tamanhos.length}
                description="Tamanhos disponíveis"
                icon={<PencilRuler className="w-6 h-6" />}
              />
              <StatsCard
                title="Total de BUs"
                value={quantities.length}
                description="BUs cadastradas"
                icon={<DollarSign className="w-6 h-6" />}
              />
              {hasChanges() && (
                <StatsCard
                  title="Valores Alterados"
                  value={editedValues.size}
                  description="Alterações pendentes"
                  icon={<AlertTriangle className="w-6 h-6" />}
                  variant="warning"
                />
              )}
            </div>
          )}
        </div>

        {/* Main Content */}
        <div className="bg-white rounded-xl shadow-sm border border-gray-200 overflow-hidden">
          {isLoading ? (
            <div className="flex flex-col items-center justify-center py-12">
              <Loader2 className="w-8 h-8 text-blue-500 animate-spin" />
              <p className="mt-4 text-gray-500">Carregando dados...</p>
            </div>
          ) : (
            <div className="p-6">
              <PriceTable
                data={data}
                tamanhos={tamanhos}
                quantities={quantities}
                isEditable={isEditable}
                editedValues={editedValues}
                originalValues={originalValuesRef.current}
                onValueChange={handleValueChange}
              />

              {isEditable && (
                <div className="mt-6 flex justify-end gap-4">
                  <button
                    onClick={() => setIsEditable(false)}
                    className="px-6 py-2.5 bg-gray-100 text-gray-700 rounded-lg hover:bg-gray-200 transition-colors inline-flex items-center gap-2"
                  >
                    <X className="w-5 h-5" />
                    Cancelar
                  </button>
                  <button
                    onClick={handleConfirm}
                    disabled={!hasChanges()}
                    className="px-6 py-2.5 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors disabled:opacity-50 disabled:cursor-not-allowed inline-flex items-center gap-2"
                  >
                    <Save className="w-5 h-5" />
                    Confirmar
                  </button>
                </div>
              )}
            </div>
          )}
        </div>
      </div>

      {/* Modals */}
      {confirmModal && (
        <div className="fixed inset-0 bg-gray-900/50 backdrop-blur-sm flex items-center justify-center p-4 z-50">
        <ConfirmModal
          title="ATENÇÃO!"
          message={message}
          onClose={(value) => {
            if (value) updateData();
            setMessage('');
          }}
        />
        </div>)}
    </div>
  );
};

export default PriceScreen;