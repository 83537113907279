import React from 'react';
import { Loader2 } from 'lucide-react';

const ProcessingMessage: React.FC = () => {
  return (
    <div className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center z-[60]">
      <div className="bg-white rounded-lg p-8 max-w-md w-full mx-4 shadow-xl">
        <div className="flex flex-col items-center text-center">
          <Loader2 className="h-12 w-12 text-blue-600 animate-spin mb-4" />
          <h2 className="text-xl font-semibold text-gray-900 mb-2">
            Processando sua solicitação
          </h2>
          <p className="text-gray-600">
            Sua campanha está sendo processada. Em alguns minutos, você poderá visualizar os dados na plataforma.
          </p>
        </div>
      </div>
    </div>
  );
};

export default ProcessingMessage;