import React, { useEffect, useState, useRef } from 'react';
import { Ticket, Search, Loader2, Upload, Download, Store } from 'lucide-react';
import * as API from '../../api';
import { PageTitle } from '../../../_metronic/layout/core';
import { Modal } from 'react-bootstrap';
import StoreListModal from '../modals/StoreListModal';
import UploadModal from '../modals/UploadVouchersModal';
import { GetPermissions } from '../../modules/auth/core/Auth';
import { StatsCard } from './StatsCard';
import { SearchInput } from './SearchInput';
import { FilterSelect } from './FilterSelect';
import { VoucherTable } from './VoucherTable';

type Props = {
  className?: string;
};

const VoucherScreen: React.FC<Props> = () => {
  const permissions = GetPermissions();
  const [uploadModal, showUploadModal] = useState(false);
  const [uploadPromise, setUploadPromise] = useState<any>(null);
  const [storeModal, showStoreModal] = useState(false);
  const [selectedVoucher, setSelectedVoucher] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState<any>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchData, setSearchData] = useState<any>([]);
  const [resultsData, setResultsData] = useState<any>([]);
  const [filter, setFilter] = useState('associated');
  const fileInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    loadVouchersList();
  }, []);

  useEffect(() => {
    applySearchAndFilter();
  }, [data, searchTerm, filter]);

  useEffect(() => {
    if (!searchTerm) {
      applyFilter(data);
    } else {
      const filteredData = searchData.map((i: any) => {
        const matchedItem = data.find((item: any) => item.IDVoucher === i.IDVoucher);
        return matchedItem ? { ...i, QuantidadeIDLojista: matchedItem.QuantidadeIDLojista } : i;
      });
      applyFilter(filteredData);
    }
  }, [searchData]);

  useEffect(() => {
    showStoreModal(selectedVoucher !== null);
  }, [selectedVoucher]);

  useEffect(() => {
    showUploadModal(uploadPromise !== null);
  }, [uploadPromise]);

  const loadVouchersList = async () => {
    setIsLoading(true);
    try {
      const response = await API.getVouchersList();
      setData(response.data);
    } catch (error) {
      console.error('Error loading vouchers:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const searchRequest = async () => {
    if (!searchTerm) {
      setSearchData([]);
      applyFilter(data);
      return;
    }

    try {
      const response = await API.searchVoucher(searchTerm);
      if (response.success) setSearchData(response.data);
    } catch (error) {
      console.error('Error searching vouchers:', error);
    }
  };

  const applyFilter = (dataToFilter: any[]) => {
    if (filter === 'all') {
      setResultsData(dataToFilter);
    } else if (filter === 'associated') {
      setResultsData(dataToFilter.filter(item => item.QuantidadeIDLojista > 0));
    } else {
      setResultsData(dataToFilter.filter(item => item.QuantidadeIDLojista === 0));
    }
  };

  const applySearchAndFilter = () => {
    if (!searchTerm) {
      applyFilter(data);
    } else {
      searchRequest();
    }
  };

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const promise = API.uploadVouchers(file);
      setUploadPromise(promise);
    }
  };

  return (
    <div className="min-h-screen bg-gray-50">
      <div className="max-w mx-auto px-4 sm:px-6 lg:px-8">
        {/* Header */}
        <div className="mb-8">
          <div className="flex items-center justify-between">
            <PageTitle>Cupons</PageTitle>
            {permissions?.some(feature => feature.name.includes('cashback-cupons-add')) && (
              <div className="flex items-center gap-4">
                <button
                  onClick={() => fileInputRef.current?.click()}
                  className="inline-flex items-center px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
                >
                  <Upload className="w-5 h-5 mr-2" />
                  Cadastrar Cupons
                </button>
                <a
                  href="https://sadataliteracyexternal.blob.core.windows.net/insight-experience/static/2563ea45-bf93-42f2-b937-09a3ba664671.xlsx"
                  className="inline-flex items-center px-4 py-2 bg-gray-100 text-gray-700 rounded-lg hover:bg-gray-200 transition-colors"
                >
                  <Download className="w-5 h-5 mr-2" />
                  Baixar modelo
                </a>
                <input
                  ref={fileInputRef}
                  type="file"
                  accept=".xlsx"
                  className="hidden"
                  onChange={handleFileChange}
                />
              </div>
            )}
          </div>

          {!isLoading && (
            <div className="mt-6 grid gap-6 md:grid-cols-2 lg:grid-cols-3">
              <StatsCard
                title="Total de Cupons"
                value={data.length}
                description="Cupons cadastrados"
                icon={<Ticket className="w-6 h-6" />}
              />
              <StatsCard
                title="Cupons Associados"
                value={data.filter((item:any) => item.QuantidadeIDLojista > 0).length}
                description="Cupons vinculados a lojas"
                icon={<Store className="w-6 h-6" />}
              />
              {searchTerm && (
                <StatsCard
                  title="Resultados da Busca"
                  value={resultsData.length}
                  description="Cupons encontrados"
                  icon={<Search className="w-6 h-6" />}
                />
              )}
            </div>
          )}
        </div>

        {/* Search and Filter */}
        <div className="mb-6 flex flex-col sm:flex-row gap-4">
          <SearchInput
            value={searchTerm}
            onChange={setSearchTerm}
            placeholder="Digite ID do Cupom ou Descrição..."
          />
          <FilterSelect value={filter} onChange={setFilter} />
        </div>

        {/* Main Content */}
        <div className="bg-white rounded-xl shadow-sm border border-gray-200 overflow-hidden">
          {isLoading ? (
            <div className="flex flex-col items-center justify-center py-12">
              <Loader2 className="w-8 h-8 text-blue-500 animate-spin" />
              <p className="mt-4 text-gray-500">Carregando dados...</p>
            </div>
          ) : !resultsData.length ? (
            <div className="flex flex-col items-center justify-center py-12">
              <div className="bg-gray-100 rounded-full p-3">
                <Ticket className="w-8 h-8 text-gray-400" />
              </div>
              <p className="mt-4 text-gray-500">Nenhum cupom encontrado</p>
            </div>
          ) : (
            <VoucherTable
              data={resultsData}
              onViewStores={setSelectedVoucher}
            />
          )}
        </div>
      </div>

      {/* Modals */}
      <Modal show={storeModal} onHide={() => setSelectedVoucher(null)}>
        <StoreListModal
          voucher={selectedVoucher}
          onClose={() => setSelectedVoucher(null)}
        />
      </Modal>

      <Modal show={uploadModal} onHide={() => setUploadPromise(null)}>
        <UploadModal
          promise={uploadPromise}
          onClose={(update = false) => {
            setUploadPromise(null);
            if (update) loadVouchersList();
          }}
        />
      </Modal>
    </div>
  );
};

export default VoucherScreen;